<template>
  <div
    v-loading="loading"
    class="guide_create"
    element-loading-text="loading..."
  >
    <img
      src="https://www2.dahuasecurity.com/asset/upload/uploads/image/20201127/pic_002(1).png"
      alt
      class="guide_create_img"
    />
    <button type="button" class="guide_create_button" @click="createApp">
      {{ LP.lang_Create_App_License }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prompt: {},
      test: 1,
      loading: false
    }
  },
  methods: {
    createApp() {
      this.$emit('childGuide', this.test)
    }
  }
}
</script>

<style lang="less" scoped>
.guide_create {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  .guide_create_img {
    margin-top: 20px;
    margin-bottom: 42px;
  }
  .guide_create_button {
    padding: 18px 24px;
    background-color: #0e5fac;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
  }
}
</style>
