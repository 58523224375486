<template>
  <div class="dhop-partner">
    <public-banner :banner-info="bannerInfo" :banner-content="bannerContent" />
    <div class="dhop-partner-top">
      <div class="dhop-partner-for container">
        <div v-for="(item, index) in forList" :key="index" class="dhop-partner-for-information">
          <p class="dhop-partner-for-information-title">{{ item.title }}</p>
          <div>{{ item.content }}</div>
        </div>
      </div>
      <!-- Why Choose ? -->
      <div class="eco-partner-choose container">
        <p class="eco-partner-choose-title">{{ LP.lang_why_choose_dahua_hardware }}</p>
        <div class="eco-partner-choose-content">
          <div
            v-for="(reason, reasonIndex) in reasonList"
            :key="reasonIndex"
            class="eco-partner-choose-reason"
          >
            <reasons-for-selection :title="reason.title" :content="reason.content" :avatar="reason.image" />
          </div>
        </div>
      </div>
    </div>
    <div class="dhop-partner-middle">
      <div class="dhop-partner-applications">
        <public-title :title="information.available_applications_title" />
        <div class="container dhop-partner-applications-content">
          <div
            v-for="(item, index) in information.application_store"
            :key="index"
            class="dhop-partner-applications-information"
          >
            <router-link :to="{path:'DHOPPartnerProgram/applicationMarketplace/subPage', query:{id: item.id}}">
              <div class="thumb">
                <div class="thumb-img" :style="`background: url(${item.image}) center center / contain no-repeat;`"></div>
              </div>
              <div class="content">
                <p class="title">{{ item.application_name }}</p>
                <p class="description">{{ item.description }}</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="button">
          <button
            class="common-button blue-button"
            @click="applicationLink"
          >{{ information.application_marketplace }}</button>
        </div>
      </div>
      <div class="dhop-partner-meet">
        <public-title :title="information.mee_our_dhop_partners" />
        <div class="container dhop-partner-meet-content">
          <div
            v-for="(item, index) in information.dhop_partner"
            :key="index"
            class="dhop-partner-meet-information"
          >
            <router-link :to="`/partners/introduction?company_name=${item.company_name}`">
              <div class="thumb">
                <div class="thumb-img" :style="`background: url(${item.company_logo}) center center / contain no-repeat;`"></div>
              </div>
              <div class="content">
                <p class="title">{{ item.company_name }}</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="button">
          <button class="common-button blue-button" @click="linkHandler">{{ information.learn_more }}</button>
        </div>
      </div>
    </div>
    <!-- Dahua Hardware Open Platform Community -->
    <div class="eco-partner-intersted">
      <p class="common-title">{{ LP.lang_dipp_dahua_open_platfrom_community }}</p>
      <button class="common-button" @click="handleIoinNow">{{ LP.lang_dipp_join_us_now }}</button>
    </div>
  </div>
</template>

<script>
import { programDhopApi } from '@/api/partnerProgram'
import ReasonsForSelection from '@/components/partnerProgram/ReasonsForSelection'
import PublicTitle from '@/components/public/PublicTitle'
import PublicBanner from '@/components/public/PublicBanner.vue'
import { mapActions } from 'vuex'
import flexibility from '@/assets/img/partnerProgram/icon-flexibility.png'
import powerAI from '@/assets/img/partnerProgram/icon-power AI.png'
import power from '@/assets/img/partnerProgram/icon-power.png'
import safety from '@/assets/img/partnerProgram/icon-Safety.png'
export default {
  components: {
    ReasonsForSelection,
    PublicTitle,
    PublicBanner
  },
  data() {
    return {
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      },
      bannerContent: {
        title: '',
        desc: '',
        link: this.LP.lang_supported_models_pdf,
        linkText: this.LP.lang_supported_models
      },
      forList: [
        {
          title: this.LP.lang_for_developers,
          content: this.LP.lang_for_developers_description
        },
        {
          title: this.LP.lang_for_system_integrators,
          content: this.LP.lang_for_system_integrators_description
        }
      ],
      reasonList: [
        {
          image: flexibility,
          title: this.LP.lang_dhop_flexibility,
          content: this.LP.lang_dhop_flexibility_description
        },
        {
          image: powerAI,
          title: this.LP.lang_dhop_powerful_ai_capacities,
          content: this.LP.lang_powerful_al_capaciti_description
        },
        {
          image: safety,
          title: this.LP.lang_safety_guarantee,
          content: this.LP.lang_safety_guarantee_description
        },
        {
          image: power,
          title: this.LP.lang_dhop_empower,
          content: this.LP.lang_dhop_empower_description
        }
      ],
      information: {}
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {
    programDhopApi().then((res) => {
      this.information = res.data
    })
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 162 })
      this.bannerInfo.img = data.data.img_image
      this.bannerContent.title = data.data.program_name
      this.bannerContent.desc = data.data.program_description
    },
    // Application Marketplace按钮页面跳转
    applicationLink() {
      this.$router.push('/partnerPrograms/DHOPPartnerProgram/applicationMarketplace')
    },
    // Join Us Now
    handleIoinNow() {
      this.$router.push('/register/Dhop')
    },
    // meet 按钮跳转
    linkHandler() {
      this.$router.push('/partners/list?type=2')
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.dhop-partner {
   /deep/ .public-banner {
     .banner-content {
       .content-desc {
         text-align: left
       }
     }
  }
  .dhop-partner-top {
    padding: 24px 0px 36px;
    .dhop-partner-for {
      margin-bottom: 72px;
      display: flex;
      .dhop-partner-for-information {
        width: calc((100% - 24px) / 2);
        margin-right: 24px;
        background: #ffffff;
        box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
        border-radius: 4px;
        padding: 32px 35px;
        &:last-child {
          margin-right: 0px;
        }
        .dhop-partner-for-information-title {
          font-size: 24px;
          font-weight: bold;
          color: #303133;
          line-height: 32px;
          margin-bottom: 16px;
        }
        div {
          // min-height: 168px;
          font-size: 16px;
          color: #606266;
          line-height: 21px;
        }
      }
    }
    .eco-partner-choose {
      .eco-partner-choose-title {
        font-size: 36px;
        color: #303133;
        line-height: 48px;
        text-align: center;
        margin-bottom: 62px;
      }
      .eco-partner-choose-content {
        display: flex;
        .eco-partner-choose-reason {
          width: calc((100% - 48px) / 4);
          margin-right: 16px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .dhop-partner-middle {
    background: #f4f4f5;
    padding: 36px 0px;
    /deep/.public-title {
      .title {
        font-size: 36px;
      }
    }
    .dhop-partner-applications {
      padding-bottom: 72px;
      .dhop-partner-applications-content {
        display: flex;
        flex-wrap: wrap;
        .dhop-partner-applications-information {
          width: calc((100% - 32px) / 3);
          margin-right: 16px;
          background-color: #fff;
          &:last-child {
            margin-right: 0px;
          }
          .thumb {
            height: 237px;
            .thumb-img {
              height: 237px;
              width: 100%;
            }
          }
          .content {
            padding: 20px;
            border-top: 1px solid #e4e7ed;
            p {
              font-size: 16px;
              color: #606266;
              line-height: 21px;
            }
            .title {
              font-size: 18px;
              font-weight: 600;
              color: #303133;
              line-height: 25px;
              margin-bottom: 10px;
            }
            .description {
              height: 65px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    .dhop-partner-meet {
      padding-bottom: 72px;
      .dhop-partner-meet-content {
        display: flex;
        flex-wrap: wrap;
        .dhop-partner-meet-information {
          width: calc((100% - 48px) / 4);
          margin-right: 16px;
          background-color: #fff;
          &:last-child {
            margin-right: 0px;
          }
          &:hover{
            .title{
              color: @theme !important;
            }
          }
          .thumb {
            height: 208px;
            overflow: hidden;
            &:hover{
              .thumb-img{
                .scale();
              }
            }
            .thumb-img {
              height: 208px;
              .transition();
            }
          }
          .content {
            padding: 10px 20px;
            border-top: 1px solid #e4e7ed;
            p {
              font-size: 16px;
              color: #606266;
              line-height: 21px;
            }
            .title {
              font-size: 18px;
              font-weight: 600;
              color: #303133;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  .eco-partner-intersted {
    height: 320px;
    background: url('~@/assets/img/partnerProgram/find_background.png') center center;
    text-align: center;
  }
  .common-title {
    font-size: 36px;
    color:#ffffff;
    line-height: 48px;
    padding: 92px 10px 48px;
  }
  .common-button {
    padding: 10px 20px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #0E5FAC;
    line-height: 21px;
    cursor: pointer;
    .transition();
    &:hover {
      opacity: 0.7;
    }
  }
	.blue-button{
		background: #0E5FAC;
		color: #ffffff;
	}
  .button {
    text-align: center;
    margin-top: 24px;
  }
  @media screen and (max-width: 1024px) {
    .dhop-partner-middle {
      .dhop-partner-applications {
        .dhop-partner-applications-content {
          .dhop-partner-applications-information {
            .thumb {
              height: 184px;
              .thumb-img {
                height: 184px;
              }
            }
          }
        }
      }
      .dhop-partner-meet {
        .dhop-partner-meet-content {
          .dhop-partner-meet-information {
            width: calc((100% - 16px) / 2);
            margin-bottom: 16px;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .dhop-partner-top {
      .dhop-partner-for {
        flex-wrap: wrap;
        margin-bottom: 24px;
        justify-content: center;
        .dhop-partner-for-information {
          width: 100%;
          padding: 20px 20px 16px;
          margin-right: 0;
          margin-bottom: 16px;
          .dhop-partner-for-information-title{
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
      .eco-partner-choose {
        .eco-partner-choose-title{
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 52px
        }
        .eco-partner-choose-content {
          flex-wrap: wrap;
          justify-content: center;
          .eco-partner-choose-reason {
            width: 100%;
            margin-right: 0;
            margin-bottom: 51px;
            max-width: 100% !important;
            /deep/.reasons .reasons-title[data-v-65b7c78f]{
              font-size: 16px;
              line-height: 21px;
            }
            /deep/.reasons .reasons-content[data-v-65b7c78f] {
              font-size: 13px;
              line-height: 17px;
              padding: 10px 16px;
            }
          }
        }
      }
    }
    .dhop-partner-middle {
      padding: 24px 0px;
      /deep/.public-title {
        .title {
          font-size: 24px;
           margin-bottom: 16px
        }
      }
      .dhop-partner-applications {
        padding-bottom: 24px;
        .dhop-partner-applications-content {
          flex-wrap: wrap;
          .dhop-partner-applications-information {
            width: 100%;
            margin-bottom: 15px;
            margin-right: 0;
            &:last-child {
              margin-bottom: 0px;
            }
            .content {
              padding: 16px;
              .description {
                height: 60px;
                font-size: 14px;
                line-height: 19px;
              }
            }
          }
        }
        .button{
          margin-top: 16px;
        }
      }
      .dhop-partner-meet {
        padding-bottom: 0;
        .dhop-partner-meet-content {
          flex-wrap: wrap;
          justify-content: center;
          .dhop-partner-meet-information {
            .content{
              padding: 10px 24px;
            }
            .thumb {
              height: 148px;
              .thumb-img {
                height: 148px;
              }
            }
          }
        }
        .button{
          margin-top: 14px;
        }
      }
    }
    .eco-partner-intersted {
      height: 200px;
			background: url('~@/assets/img/partnerProgram/find-mobile.png') center center;
      .common-title {
        font-size: 24px;
        line-height: 32px;
        padding: 29px 20px 19px;
      }
      .common-button{
        padding: 14px 20px;
      }
    }
    /deep/.public-banner .banner-content .content-title[data-v-dd598cca] {
      font-size: 24px;
      line-height: 24px;
    }
    /deep/.public-banner .banner-content .content-desc[data-v-dd598cca] {
      font-size: 14px;
      margin-top: 16px;
    }
  }
}
</style>
