import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  program: '/partner_program/partner_program_home', //  partnerProgram模块首页
  programDhop: '/partner_program/dhop_partner_program', //  DHOPPartnerProgram模块
  applicationList: '/partner_program/application_marketplace', // ApplicationMarketplace
  sdkList: '/dhop_manager/download_list', //  DHOPManager ---> SDK Files Download
  versionList: '/dhop_manager/dhop_version', // DHOPManager ---> Debug Enviroment ---> Target List
  targetList: '/dhop_manager/app_list', // DHOPManager ---> Debug Enviroment ---> Target List
  accessDownload: 'dhop_manager/download_dhop_file', // DHOPManager ---> access method
  downloadLicense: '/dhop_manager/download_new', // DHOPManager ---> Debug Enviroment ---> Download License
  dhopLicenses: '/dhop_manager/index', // DHOP license获取列表
  licenseDownload: '/dhop_manager/download', // DHOP license list 下载
  licenseList: '/dhop_manager/license_list', // DHOP License 申请列表接口
  licenseAppName: '/dhop_manager/app', // DHOP 存储stepOne数据
  licenseKeyList: '/dhop_manager/key_list', // DHOP StepThree 下拉数据
  licenseRsa: 'dhop_manager/rsa', // DHOP 生成秘钥
  licenseCheackRsa: '/dhop_manager/public_key_cheak', // DHOP 验证秘钥
  licenseKey: '/dhop_manager/key', // DHOP 存储stepTwo数据
  dhopDetele: '/dhop_manager/delete', // DHOP 删除
  keyInfoKey: '/dhop_manager/key_update_message', // DHOP keyInfo 获取数据
  dhopNav: '/dhop_manager/nav' // DHOP 面包屑
}

//  partnerProgram首页
export const programApi = () => _axios.get(apiUrl.program)
// DHOPPartnerProgram模块
export const programDhopApi = () => _axios.get(apiUrl.programDhop)
// ApplicationMarketplace
export const applicationListpApi = (params) => _axios.post(apiUrl.applicationList, params)
// DHOPManager ---> SDK Files Download
export const sdkList = () => _axios.get(apiUrl.sdkList)
// DHOPManager ---> Debug Enviroment
export const versionListApi = () => _axios.get(apiUrl.versionList)
// DHOPManager ---> Debug Enviroment ---> Target List
export const targetListApi = () => _axios.get(apiUrl.targetList)
// DHOPManager ---> Debug Enviroment ---> access method
export const accessDownloadApi = (params) => _axios.get(apiUrl.accessDownload + `?file_url=${params.file_url}`)
// DHOPManager ---> Debug Enviroment ---> Download License
export const downloadLicenseApi = () => _axios.get(apiUrl.downloadLicense)
// DHOP license获取列表
export const dhopLicensesApi = (params) => _axios.post(apiUrl.dhopLicenses, params)
// DHOP license list 下载
export const licenseDownloadApi = (params) => _axios.post(apiUrl.licenseDownload, params)
// DHOP License 申请列表接口
export const licenseListApi = (params) => _axios.post(apiUrl.licenseList, params)
// DHOP 存储stepOne数据
export const licenseAppNameApi = (params) => _axios.post(apiUrl.licenseAppName, params)
// DHOP StepThree 下拉数据
export const licenseKeyListApi = (params) => _axios.get(apiUrl.licenseKeyList + `?type=${params.type}`)
// DHOP 生成秘钥
export const licenseRsaApi = (params) => _axios.get(apiUrl.licenseRsa + `?key_name=${params.key_name}`)
// DHOP 验证秘钥
export const licenseCheackRsaApi = (params) => _axios.post(apiUrl.licenseCheackRsa, params)
// DHOP 存储stepTwo数据
export const licenseKeyApi = (params) => _axios.post(apiUrl.licenseKey, params)
// DHOP 删除
export const dhopDeteleApi = (params) => _axios.post(apiUrl.dhopDetele, params)
// DHOP keyInfo 获取数据
export const keyInfoKeyApi = (params) => _axios.post(apiUrl.keyInfoKey, params)
// DHOP 面包屑
export const dhopNavApi = (params) => _axios.post(apiUrl.dhopNav, params)
