<template>
  <div class="license_list">
    <div class="key_search">
      <el-input
        v-model="input"
        :placeholder="prompt.lang_enter_application_name"
        @keyup.enter.native="search"
      />
      <i class="el-icon-search search" @click="search"></i>
    </div>
    <span class="create_download" @click="createApp">
      <i class="iconfont icon-chuangjiandanju"></i>
      <span>{{ prompt.lang_dhop_create_app_name }}</span>
    </span>
    <div class="key_list">
      <p class="key_list_days">{{ prompt.lang_dhop_days }}</p>
      <p class="key_list_header">
        <span>{{ prompt.lang_dhop_app_name }}</span>
        <span>{{ prompt.lang_dhop_opreation }}</span>
      </p>
    </div>
    <div
      v-for="(item, index) in nameList"
      :key="index"
      class="key_list_content"
      :class="item.isShow ? 'key_list_jianju' : ''"
    >
      <div class="list-item" :class="item.isShow ? 'boeder_line' : ''">
        <div class="left">
          <div class="unfold">
            <i
              :class="
                item.isShow ? 'el-icon-caret-bottom' : 'el-icon-caret-right'
              "
              @click="showInformation(item, index)"
            ></i>
          </div>
          <span
            class="key_list_name key_list_title"
            :class="{ active: item.isShow }"
            :title="item.app_name"
          >{{ item.app_name }}</span>
        </div>
        <div class="right">
          <span
            class="key_list_create"
            @click="createLicense(item.app_name, item.id)"
          >{{ prompt.lang_dhop_create_license }}</span>
          <span class="key_list_delete" @click="detele(item.id)">{{
            prompt.lang_dhop_delete
          }}</span>
        </div>
      </div>

      <el-table
        v-show="item.isShow"
        v-if="item.license && item.license.length > 0"
        :data="item.license"
        style="width: 100%"
        class="child_list"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="cellStyle"
      >
        <el-table-column type="index" label=" " width="20" align="center" />
        <el-table-column
          prop="appVersion"
          :label="LP.lang_dhop_app_version"
          width="120"
          align="center"
        />
        <el-table-column
          prop="key_name"
          :label="LP.lang_dhop_key_name"
          width="120"
          align="center"
        >
          <template slot-scope="props">
            <span :title="props.row.key_name" class="key_name">{{
              props.row.key_name
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="effective"
          :label="LP.lang_valid_days"
          width="120"
          align="center"
        />
        <el-table-column
          prop="type"
          :label="LP.lang_lic_type"
          width="120"
          align="center"
        />
        <el-table-column
          prop="hardware_number"
          :label="LP.lang_lic_num"
          width="120"
          align="center"
        />
        <el-table-column
          prop="status"
          :label="LP.lang_review_status"
          width="120"
          align="center"
        />
        <el-table-column
          prop="delete_file_date"
          :label="LP.lang_link_lnvalid_date"
          width="136"
          align="center"
        />
        <el-table-column prop="" label="Operation" align="center">
          <template slot-scope="props">
            <template style="border-bottom: 1px solid #000">
              <i
                v-if="props.row.status === 'Examined'"
                class="iconfont icon-a-xiazai1x download_detele"
                @click="childDownload(props.row.license_id)"
              ></i>
              <i
                v-if="
                  props.row.status === 'Not Pass' ||
                    props.row.status === 'Examined'
                "
                class="el-icon-delete download_detele"
                :class="{ not_pass: props.row.status === 'Not Pass' }"
                @click="childDetele(props.row.license_id, item.isShow)"
              ></i>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <p v-if="nameList && nameList.length == 0" class="no_data">
      {{ prompt.lang_dhop_no_data }}
    </p>
  </div>
</template>

<script>
import { licenseListApi, dhopDeteleApi, licenseDownloadApi } from '@/api/partnerProgram'
export default {
  data() {
    return {
      prompt: {
        lang_enter_application_name: this.LP.lang_enter_application_name,
        lang_dhop_create_app_name: this.LP.lang_dhop_create_app_name,
        lang_dhop_days: this.LP.lang_dhop_days,
        lang_dhop_app_name: this.LP.lang_dhop_app_name,
        lang_dhop_opreation: this.LP.lang_dhop_opreation,
        lang_dhop_create_license: this.LP.lang_dhop_create_license,
        lang_dhop_delete: this.LP.lang_dhop_delete,
        lang_dhop_no_data: this.LP.lang_dhop_no_data,
        lang_dhop_detele_prompt: this.LP.lang_dhop_detele_prompt,
        lang_dhop_detele_title: this.LP.lang_dhop_detele_title,
        lang_dhop_ok: this.LP.lang_dhop_ok,
        lang_dhop_delete_success: this.LP.lang_dhop_delete_success
      },
      input: '',
      isActive: -1,
      nameList: [],
      test: 1,
      num: 3,
      isShow: false,
      customShow: 6
    }
  },
  created() {
    this.getLicenseList(0, this.input)
  },
  methods: {
    // 获取license列表接口
    getLicenseList(category, keyword) {
      licenseListApi({
        category: category,
        keyword: keyword
      }).then(res => {
        this.loading = false
        this.licenseInformation = res.data
        this.nameList = res.data.license_list
      })
    },
    showInformation(item, i) {
      if (!item.isShow) {
        this.$set(item, 'isShow', false)
        item.isShow = !item.isShow
        this.isActive = i
      } else {
        item.isShow = !item.isShow
        this.isActive = -1
      }
    },
    createApp() {
      this.$emit('detailList', this.test)
      localStorage.setItem('customShow', this.customShow)
    },
    search() {
      this.getLicenseList(0, this.input)
    },
    detele(id) {
      this.$confirm(
        this.prompt.lang_dhop_detele_prompt,
        this.prompt.lang_dhop_detele_title,
        {
          type: 'warning'
        }
      )
        .then(() => {
          dhopDeteleApi({
            id: id,
            type: 'APP'
          }).then(res => {
            this.$alert(this.prompt.lang_dhop_delete_success, {
              confirmButtonText: this.prompt.lang_dhop_ok,
              type: 'success',
              callback: (action) => {
                this.getLicenseList(0)
              }
            })
          })
        })
        .catch(() => {
        })
    },
    childDetele(id, show) {
      this.$confirm(
        this.prompt.lang_dhop_detele_prompt,
        this.prompt.lang_dhop_detele_title,
        {
          type: 'warning'
        }
      )
        .then(() => {
          dhopDeteleApi({
            id: id,
            type: 'License'
          }).then(res => {
            this.$alert(this.prompt.lang_dhop_delete_success, {
              confirmButtonText: this.prompt.lang_dhop_ok,
              type: 'success',
              callback: (action) => {
                this.getLicenseList(0)
              }
            })
          })
        })
        .catch(() => {
        })
    },
    // 点击Create License按钮
    createLicense(name, id) {
      this.$router.push({
        name: 'CreateLicense',
        query: { name: name, id: id }
      })
    },
    download() {
      this.$router.push({ name: 'download' })
    },
    // 下载
    childDownload(id) {
      licenseDownloadApi({
        id: id
      }).then((res) => {
        window.location.href = res.data
      })
    },
    cellStyle(data) {
      if (data.columnIndex === 6 && data.row.status === 'Examined') {
        return 'color: #2dd300; font-weight: bold;'
      } else if (data.columnIndex === 6 && data.row.status === 'Not Pass') {
        return 'color: #ff2727; font-weight: bold;'
      } else if (data.columnIndex === 6) {
        return 'font-weight: bold;'
      } else {
        return
      }
    }
  }
}
</script>

<style lang="less" scoped>
.license_list {
  position: relative;
  .key_search {
    display: inline-block;
    width: 450px;
    height: 44px;
    border-radius: 22px;
    border: solid 1px #dadada;
    .search {
      cursor: pointer;
      position: absolute;
      top: 0;
      height: 100%;
      color: #c0c4cc;
      text-align: center;
      left: 321px;
      font-size: 20px;
      padding-top: 11px;
    }
  }
  .create_download {
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    color: #0e5fac;
    margin-left: 30px;
    i {
      font-size: 24px;
      vertical-align: middle;
    }
    span {
      text-decoration: underline;
    }
  }
  .key_list {
    margin-top: 40px;
    border-bottom: solid 2px #dadada;
    padding-bottom: 7px;
    .key_list_days {
      padding-bottom: 10px;
      border-bottom: solid 2px #dadada;
      margin-bottom: 10px;
      color: #606266;
    }
    .key_list_header {
      display: flex;
      justify-content: space-between;
      span {
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        line-height: 29px;
        color: #333333;
        &:first-child {
          padding: 0 61px 0 74px;
        }
        &:last-child {
          padding-left: 20%;
        }
      }
    }
  }
  .key_list_content {
    padding: 30px 0;
    border-bottom: solid 1px #dadada;
    overflow: hidden;
    .list-item {
      display: flex;
      justify-content: space-between;
      .left {
        flex: 1;
        .unfold {
          display: inline-block;
          width: 74px;
          text-align: center;
          i {
            font-size: 18px;
            cursor: pointer;
          }
        }
        .key_list_name {
          display: inline-block;
          width: 30%;
          font-size: 16px;
          font-weight: bold;
          color: #666666;
        }
        .key_list_title {
          display: inline-block;
          width: 240px;
          padding-right: 61px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .active {
          color: #0e5fac;
        }
      }
      .right {
        flex: 1;
        text-align: center;
        .key_list_create {
          cursor: pointer;
          font-weight: bold;
          padding: 8px 10px;
          border-radius: 20px;
          border: solid 1px #0e5fac;
          font-size: 16px;
          color: #0e5fac;
          // margin-left: 54%;
          margin-right: 15px;
        }
        .key_list_delete {
          cursor: pointer;
          font-weight: bold;
          padding: 8px 25px;
          border-radius: 20px;
          border: solid 1px #0e5fac;
          font-size: 16px;
          color: #0e5fac;
        }
      }
    }
    .child_list {
      margin-left: 50px;
    }
    .boeder_line {
      padding-bottom: 30px;
      border-bottom: solid 1px #dadada;
    }
  }
  .key_list_jianju {
    padding: 30px 0 0 0;
  }
  .no_data {
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    color: #666;
  }
  .dialog_span {
    color: #666666;
    font-size: 16px;
  }
  .create_none {
    margin-left: 275px;
  }
  .download_detele {
    font-size: 21px;
    vertical-align: middle;
    color: #0e5fac;
    font-weight: bold;
    cursor: pointer;
    &:last-child {
      padding-left: 20px;
    }
  }
  .not_pass {
    padding-left: 41px !important;
  }
  .key_name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  @media screen and (max-width: 1024px) {
    .key_list_content {
      .child_list {
        margin-left: 0px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .key_list_content {
      .list-item {
        .left {
          .unfold {
            width: auto;
          }
          .key_list_title {
            width: auto;
            max-width: 240px;
          }
        }
        .right {
          .key_list_create {
            font-size: 14px;
            margin-right: 10px;
            padding: 6px 8px;
          }
          .key_list_delete {
            font-size: 14px;
            padding: 6px 8px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.license_list {
  .el-input__inner {
    padding-right: 0;
    margin-left: 120px;
    border: none;
    max-width: 190px;
  }
  .el-icon-caret-bottom {
    color: #0e5fac;
  }
  .el-dialog__footer {
    text-align: center;
  }
  .el-button--primary {
    background-color: #0e5fac;
    border-color: #0e5fac;
  }
  .el-dialog__body {
    text-align: center;
  }
  .el-table .cell,
  .el-table th div {
    padding-left: 0;
  }
}
</style>
