<template>
  <div class="eco-partner">
    <public-banner :banner-info="bannerInfo" :banner-content="bannerContent" />
    <div class="partner-classification container">
      <div
        v-for="(item, index) in ficationList"
        :key="index"
        class="partner-classification-content"
        :style="{borderTop: `6px solid ${item.color}`}"
      >
        <p :style="{color: item.color}" class="partner-classification-title">{{ item.title }}</p>
        <div class="partner-classification-information" v-html="item.content"></div>
      </div>
    </div>
    <!-- Why Choose ? -->
    <div class="eco-partner-choose container">
      <p class="eco-partner-choose-title">{{ LP.lang_choose_partner_program }}</p>
      <div class="eco-partner-choose-content">
        <div
          v-for="(reason, reasonIndex) in reasonList"
          :key="reasonIndex"
          class="eco-partner-choose-reason"
        >
          <reasons-for-selection :title="reason.title" :content="reason.content" :avatar="reason.image" />
        </div>
      </div>
    </div>
    <!-- Service to Requirements -->
    <div class="eco-partner-tabs container">
      <public-tabs-round :data="tabs" @tabsClick="tabsClick" />
      <div class="eco-partner-tabs-content">
        <div v-if="index == 0">
          <table cellpadding="0" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th width="170"></th>
                <th></th>
                <th width="170">{{ LP.lang_synergy_partner }}</th>
                <th width="170">{{ LP.lang_synergy_plus_partner }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="left">
                        <span class="table-left-title">{{ LP.lang_general }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_accessibility }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_partner_portal_access }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">{{ LP.lang_open }}</td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">{{ LP.lang_after_review }}</td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="left">
                        <span class="table-left-title">{{ LP.lang_technology }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_technical_support }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_roadmap_sharing }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td rowspan="4" align="left">
                        <span class="table-left-title">{{ LP.lang_marketing }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_synergy_partner_content }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_communication_on_media }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_cases_communication }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_dedicated_training }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center">{{ LP.lang_online }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">{{ LP.lang_face_to_face }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="left">
                        <span class="table-left-title">{{ LP.lang_sales }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_project_support }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_cooperation_with_local }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table cellpadding="0" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th width="170"></th>
                <th></th>
                <th width="170">{{ LP.lang_synergy_partner }}</th>
                <th width="170">{{ LP.lang_synergy_plus_partner }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="left">
                        <span class="table-left-title">{{ LP.lang_general }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_signed_agreement }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_regular_meetings }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="left">
                        <span class="table-left-title">{{ LP.lang_technology }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_dedicated_technical_support }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_roadmap_sharing }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="left">
                        <span class="table-left-title">{{ LP.lang_marketing }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_dahua_content_on_synergy_website	}}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_regular_joint_activities }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="left">
                        <span class="table-left-title">{{ LP.lang_sales }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_project_support }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_cooperation_with_local	}}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-icon-a-gou1x-new" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Ready to Beacome -->
    <div class="eco-partner-become">
      <p class="common-title">{{ LP.lang_ready_to_beacome_a_synergy_partner }}</p>
      <button class="common-button join-button" @click="handleIoinNow('0')">{{ LP.lang_dipp_join_us_now }}</button>
    </div>
    <!-- Intersted in Synergy Partners -->
    <div class="eco-partner-intersted">
      <p class="common-title find-title">{{ LP.lang_do_you_intersted_in_synergy_partners }}</p>
      <button class="common-button find-button" @click="handleIoinNow('2')">{{ LP.lang_find_a_synergy_partner }}</button>
    </div>
  </div>
</template>

<script>
import ReasonsForSelection from '@/components/partnerProgram/ReasonsForSelection'
import PublicTabsRound from '@/components/public/PublicTabsRound'
import PublicBanner from '@/components/public/PublicBanner.vue'
import Img1 from '@/assets/img/partnerProgram/icon-training.png'
import Img2 from '@/assets/img/partnerProgram/icon-power.png'
import Img3 from '@/assets/img/partnerProgram/icon-link.png'
import { mapActions } from 'vuex'
export default {
  components: {
    ReasonsForSelection,
    PublicTabsRound,
    PublicBanner
  },
  data() {
    return {
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      },
      bannerContent: {
        title: '',
        desc: '',
        link: '',
        linkText: ''
      },
      ficationList: [
        {
          title: this.LP.lang_dahua_red_synergy_partner,
          color: '#C8161D',
          content: `<p style="margin-bottom: 15px;">${this.LP.lang_dahua_red_synergy_partner_description}</p>`
        },
        {
          title: this.LP.lang_dahua_white_synergy_partner,
          color: '#D4D4D4',
          content: `<p style="margin-bottom: 15px;">${this.LP.lang_dahua_white_synergy_partner_description}</p>`
        },
        {
          title: this.LP.lang_dahua_blue_synergy_partner,
          color: '#0E5FAC',
          content: `<p style="margin-bottom: 15px;">${this.LP.lang_dahua_blue_synergy_partner_description}</p>`
        }
      ],
      reasonList: [
        {
          image: Img1,
          title: this.LP.lang_technical_guidance,
          content: this.LP.lang_technical_guidance_description
        },
        {
          image: Img2,
          title: this.LP.lang_brand_exposure,
          content: this.LP.lang_brand_exposure_description
        },
        {
          image: Img3,
          title: this.LP.lang_market_access,
          content: this.LP.lang_market_access_description
        }
      ],
      tabs: [
        { name: this.LP.lang_service },
        { name: this.LP.lang_requirements }
      ],
      index: 0
    }
  },
  created() {
    this.getBanner()
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 82 })
      this.bannerInfo.img = data.data.img_image
      this.bannerContent.title = data.data.program_name
      this.bannerContent.desc = data.data.program_description
    },
    tabsClick(item, index) {
      this.index = index
    },
    // Join Us Now
    handleIoinNow(i) {
      if (i === '0') {
        this.$router.push('/register/Synergy')
      } else {
        this.$router.push('/partners/list?type=3')
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/pages/partnerProgram/common";
@import '~@/styles/common/common';
.eco-partner {
   /deep/ .public-banner {
     .banner-content {
       .content-desc {
         text-align: left
       }
     }
  }
  .common-button {
    .transition();
    &:hover {
      opacity: 0.7;
    }
  }
	.join-button{
    background-color: #C8161D;
    color: #ffffff;
  }
	.find-title{
		color: #ffffff;
	}
	.find-button{
		background-color: #ffffff;
		color: #C8161D;
	}
}
</style>
