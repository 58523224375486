<template>
  <div class="partner-program">
    <public-banner :banner-info="bannerInfo" />
    <div v-for="(item,index) in programList" :key="index" class="program-category" @click="programLink(item)">
      <div v-if="index%2===0" class="program-content">
        <div class="program-content-left">
          <p class="program-content-title">{{ item.nav_name }}</p>
          <div class="program-content-content" v-html="item.nav_description"></div>
        </div>
        <div class="program-content-right">
          <img :src="item.nav_image" alt class="program-content-image" />
        </div>
      </div>
      <div v-else class="program-content">
        <div class="program-content-left hidden-mobile">
          <img :src="item.nav_image" alt class="program-content-image" />
        </div>
        <div class="program-content-right">
          <p class="program-content-title">{{ item.nav_name }}</p>
          <div class="program-content-content" v-html="item.nav_description"></div>
        </div>
        <div class="program-content-left hidden-pc">
          <img :src="item.nav_image" alt class="program-content-image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { programApi } from '@/api/partnerProgram'
import PublicBanner from '@/components/public/PublicBanner.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    PublicBanner
  },
  data () {
    return {
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      },
      // partnerProgram首页数据
      programList: []
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {
    // 首页接口
    programApi().then((res) => {
      this.programList = res.data
    })
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 152 })
      this.bannerInfo.title = data.data.img_title
      this.bannerInfo.img = data.data.img_image
    },
    programLink(item) {
      if (item.nav_linktype === '1') {
        this.$router.push(`${item.nav_link_name}`)
      } else {
        window.open(item.nav_linkurl)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.partner-program {
  .program-category {
    .program-content {
      max-width: 1330px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 64px 0px;
      cursor: pointer;
      @media screen and (max-width: 1350px) {
        width: 92%;
      }
      .program-content-left {
        max-width: 648px;

      }
      .program-content-right {
        max-width: 632px;
      }
      .program-content-title {
        font-size: 36px;
        color: #303133;
        line-height: 48px;
      }
      .program-content-content {
        font-size: 16px;
        color: #606266;
        line-height: 1.5;
        margin-top: 48px;
        max-width: 648px;
      }
      .program-content-image {
        max-width: 632px;
        width: 100%;
      }
      .hidden-mobile {
        display: block;
      }
      .hidden-pc {
        display: none;
      }
    }
    &:nth-child(even) {
      background: #f4f4f5;
      .program-content-left {
        margin-right: 50px;
      }
    }
    &:nth-child(odd) {
      background: #ffffff;
      .program-content-right {
        margin-left: 50px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    /deep/.public-banner .banner-bg .banner-info .banner-title[data-v-dd598cca]{
      font-size: 24px;
      line-height: 32px;
    }
    .program-category {
      .program-content {
        flex-wrap: wrap;
        padding: 24px 0;
        .program-content-left {
          width: 100%;
          margin-bottom: 16px;
          margin-right: 0px;
        }
        .program-content-right {
          width: 100%;
          margin-left: 0px;
          margin: 0 auto;
        }
        .program-content-title {
          font-size: 24px;
          line-height: 32px;
          text-align: center;
        }
        .program-content-content {
          font-size: 14px;
          color: #606266;
          margin-top: 16px;
        }
        .hidden-mobile {
          display: none;
        }
        .hidden-pc {
          display: block;
          margin: 0 auto;
          margin-top: 20px;
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
