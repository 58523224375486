<template>
  <div v-loading="loading" class="step_three" element-loading-text="loading...">
    <div class="container">
      <BreadCrumb :type="crumb" />
      <div class="step_three_content">
        <!-- 单选组 -->
        <div class="step_app_name">
          <el-radio-group v-model="reverse" @change="changeRadio">
            <el-radio :label="LP.lang_development_license" />
            <el-radio :label="LP.lang_distribution_license" />
          </el-radio-group>
        </div>
        <!-- App Name -->
        <div class="step_agreement">
          <span>
            {{ prompt.lang_dhop_app_name }}
            <span>*</span>
          </span>
          <el-input v-model="appName" :disabled="true" />
        </div>
        <!-- Key Name -->
        <div class="step_agreement" :class="{ device: showKeyName === true }">
          <span>
            {{ prompt.lang_dhop_key_name }}
            <span>*</span>
          </span>
          <!-- <el-input v-model="keyName"></el-input> -->
          <el-select v-model="keyName">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.key_name"
              :value="item.id"
            />
          </el-select>
        </div>
        <p v-if="showKeyName" class="error error_bottom">
          {{ prompt.lang_dhop_key_name_cannot_be_empty }}
        </p>
        <!-- App Version -->
        <div class="step_agreement" :class="{ device: showVersion === true }">
          <span>
            {{ prompt.lang_dhop_app_version }}
            <span>*</span>
          </span>
          <el-input v-model="appVersion" @input="hiddenError" />
        </div>
        <p v-if="showVersion" class="error error_bottom">
          {{ prompt.lang_error_app_version }}
        </p>
        <!-- Device UID -->
        <div class="step_agreement device">
          <span>{{ prompt.lang_dhop_device_uid }}</span>
          <el-input
            v-model="file"
            :placeholder="prompt.lang_dhop_uuid_desc"
          />
        </div>
        <p class="device_desc">
          <a @click="UUIDAccess">{{ prompt.lang_dhop_access_method }}</a>
          <a @click="UIDTemaplate">{{ prompt.lang_dhop_download_template }}</a>
        </p>
        <!-- Vaild days -->
        <div class="step_agreement vaild" :class="{ step_bottom: showValidDay }">
          <span>
            {{ prompt.lang_valid_days }}
            <span>*</span>
          </span>
          <el-input
            v-model="valid"
            :disabled="disabled"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            maxlength="5"
            @input="showValidHidden"
          />
        </div>
        <p v-if="showValidDay" class="error error_bottom">
          {{ prompt.lang_dhop_app_name_cannot_be_empty }}
        </p>
        <p v-if="reverseRadio === 'Distribution'" class="vaild_desc">{{ prompt.lang_dhop_vaild_desc }}</p>
        <p v-else class="vaild_desc">{{ prompt.lang_dhop_download_vaild }}</p>
        <!-- 上传模板按钮 -->
        <el-upload
          ref="upload"
          class="step_create_key"
          :action="uploadUrl"
          :on-change="handleChange"
          :show-file-list="showFileList"
          :on-success="handleSuccess"
          :file-list="fileList"
        >
          <button class="dhop_application_file_button" type="button">
            {{ prompt.lang_dhop_batch_import }}
          </button>
        </el-upload>
        <div class="create-key" @click="handleCreateInfo">
          <button>{{ prompt.lang_list_create_key }}</button>
        </div>
      </div>
      <div class="step_button">
        <button type="button" class="step_two_next" @click="stepThreeNext">
          {{ prompt.lang_dhop_apply_for_license }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { _axios } from '@/plugins/axios.js'
import { licenseKeyListApi, dhopLicensesApi } from '@/api/partnerProgram'
import BreadCrumb from '@/components/partnerProgram/BreadCrumb'
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      prompt: {
        lang_dhop_app_name: this.LP.lang_dhop_app_name,
        lang_dhop_key_name: this.LP.lang_dhop_key_name,
        lang_dhop_key_name_cannot_be_empty: this.LP.lang_dhop_key_name_cannot_be_empty,
        lang_dhop_app_version: this.LP.lang_dhop_app_version,
        lang_error_app_version: this.LP.lang_error_app_version,
        lang_dhop_device_uid: this.LP.lang_dhop_device_uid,
        lang_dhop_uuid_desc: this.LP.lang_dhop_uuid_desc,
        lang_dhop_access_method: this.LP.lang_dhop_access_method,
        lang_dhop_download_template: this.LP.lang_dhop_download_template,
        lang_valid_days: this.LP.lang_valid_days,
        lang_dhop_app_name_cannot_be_empty: this.LP.lang_dhop_app_name_cannot_be_empty,
        lang_dhop_vaild_desc: this.LP.lang_dhop_vaild_desc,
        lang_dhop_download_vaild: this.LP.lang_dhop_download_vaild,
        lang_dhop_batch_import: this.LP.lang_dhop_batch_import,
        lang_list_create_key: this.LP.lang_list_create_key,
        lang_dhop_apply_for_license: this.LP.lang_step_three_title,
        lang_dhop_apply_success: this.LP.lang_dhop_apply_success
      },
      reverse: 'Development License',
      reverseRadio: '',
      showKeyName: false,
      showValidDay: false,
      appName: '',
      keyName: '',
      appVersion: '',
      valid: '90',
      custom: '',
      options: [],
      test: -1,
      num: 2,
      baseURL: process.env.VUE_APP_BASE_API,
      uploadUrl: _axios.defaults.baseURL + '/dhop_manager/import',
      fileList: [],
      showFileList: false,
      file: '',
      information: {},
      disabled: true,
      showVersion: false,
      loading: true
    }
  },
  created() {
    this.crumb = 'license_info'
    this.appName = this.$route.query.name
    licenseKeyListApi({
      type: ''
    }).then(res => {
      this.options = res.data
      this.loading = false
    })
  },
  methods: {
    stepThreeNext() {
      if (this.keyName === '') {
        this.showKeyName = true
        return
      }
      if (this.appVersion === '') {
        this.showVersion = true
        return
      }
      if (this.valid === '') {
        this.showValidDay = true
      } else {
        dhopLicensesApi({
          type: this.reverseRadio,
          app_name_id: this.$route.query.id,
          key_name_id: this.keyName,
          app_version: this.appVersion,
          hardware_id: this.file,
          effective: this.valid,
          custom_Field: this.custom,
          licLevel: 0
        }).then(res => {
          this.$alert(this.prompt.lang_dhop_apply_success, {
            confirmButtonText: this.prompt.lang_dhop_ok,
            type: 'success',
            callback: (action) => {
              this.$router.push({ name: 'LicenseInfo' })
            }
          })
        })
      }
    },
    changeRadio(val) {
      if (val === 'Distribution License') {
        this.reverseRadio = 'Distribution'
        this.disabled = false
        this.valid = ''
      } else {
        this.reverseRadio = 'Development'
        this.disabled = true
        this.valid = '90'
      }
    },
    changeOption() {
      this.showKeyName = false
    },
    showValidHidden() {
      this.showValidDay = false
      if (this.valid > 10800) {
        this.valid = 10800
      }
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1)
    },
    handleSuccess(response, file, fileList) {
      const data = response.data
      this.file = data
    },
    handleCreateInfo() {
      this.$router.push({
        name: 'KeyInfo'
      })
    },
    hiddenError() {
      this.showVersion = false
    },
    UUIDAccess() {
      this.download_url = this.prompt.lang_dhop_Device_UUID
      this.Dwonload()
    },
    UIDTemaplate() {
      this.download_url = this.prompt.lang_dhop_CPUID_Template
      this.Dwonload()
    },
    Dwonload() {
      this.$store
        .dispatch('myPage/getDownloadFile', {
          file_url: this.download_url
        })
        .then((res) => {
          location.href = res.data
        })
    }
  }
}
</script>

<style lang="less" scoped>
.step_three {
  padding: 50px 0;
  .step_three_content {
    width: 82%;
    display: inline-block;
    vertical-align: top;
    padding-top: 8px;
    position: relative;
    .step_step {
      font-size: 18px;
      font-weight: bold;
      color: #0e5fac;
    }
    .step_app_name {
      padding-bottom: 18px;
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0px;
      color: #666666;
    }
    .step_agreement {
      width: 100%;
      border: solid 1px #dadada;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      > span {
        display: inline-block;
        padding: 10px 20px;
        &:first-child {
          width: 221px;
          padding: 12px 0;
          text-align: center;
          color: #666666;
          border-right: solid 1px #dadada;
          > span {
            color: #ff0000;
            font-weight: bold;
          }
        }
        &:last-child {
          a {
            text-decoration: underline;
            color: #0e5fac;
          }
        }
      }
      > .el-input,
      > .el-select {
        width: calc(100% - 221px);
      }
    }
    .vaild,
    .device {
      margin-bottom: 0;
    }
    .step_bottom {
      margin-bottom: 0;
    }
    .step_create_key {
      cursor: pointer;
      padding: 1px;
      border-radius: 5px;
      border: solid 1px #0e5fac;
      background-color: #fff;
      position: absolute;
      top: 290px;
      right: -175px;
      button {
        cursor: pointer;
        font-size: 18px;
        color: #0e5fac;
        background-color: #fff;
        padding: 13px 18px;
      }
    }
    .create-key {
      button {
        cursor: pointer;
        padding: 14px 24px;
        border-radius: 5px;
        border: solid 1px #0e5fac;
        background-color: #fff;
        font-size: 18px;
        color: #0e5fac;
        position: absolute;
        top: 135px;
        right: -175px;
      }
    }
    .vaild_desc {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      color: #666666;
    }
    .device_desc {
      text-align: right;
      margin-top: 10px;
      margin-bottom: 20px;
      a {
        text-decoration: underline;
        font-size: 16px;
        color: #0e5fac;
        cursor: pointer;
        &:first-child {
          padding-right: 70px;
        }
      }
    }
  }
  .step_button {
    text-align: center;
    margin-top: 41px;
    .step_two_previous {
      cursor: pointer;
      padding: 15px 65px;
      margin-right: 40px;
      border-radius: 5px;
      border: solid 1px #dadada;
      background-color: #fff;
      font-size: 18px;
      color: #666666;
    }
    .step_two_next {
      cursor: pointer;
      padding: 13px 37px;
      background-color: #0e5fac;
      border-radius: 5px;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .error {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
  .error_bottom {
    margin-bottom: 4px;
  }
}
</style>
<style lang="less">
.step_three {
  .el-input__inner {
    border: none;
    height: 55px;
    font-size: 16px;
  }
  .el-radio__label {
    font-size: 16px;
  }
}
</style>
