<template>
  <div v-loading="loading" class="debug-enviroment" element-loading-text="loading...">
    <div class="debug-content container">
      <div class="step_three_content">
        <!-- DHOP-SDK Version -->
        <div class="step_agreement">
          <span>{{ prompt.lang_dhop_sdk_version }}<span>*</span></span>
          <el-select
            v-model="SDKVersion"
            :placeholder="prompt.lang_dhop_sdk_version"
            @change="changeVersion"
          >
            <el-option
              v-for="(version, index) in versions"
              :key="index"
              :label="version.dhop_version"
              :value="version"
            />
          </el-select>
        </div>
        <p
          class="device_desc access"
          :class="{ step_bottom: showVersion == true }"
        >
          <a @click="AccessMethod">{{
            prompt.lang_dhop_sdk_ersion_access_method
          }}</a>
        </p>
        <p v-if="showVersion" class="error error_bottom">
          {{ prompt.lang_error_app_version }}
        </p>
        <!-- APP Name -->
        <div class="step_agreement">
          <span>{{ prompt.lang_dhop_app_name }}</span>
          <el-input v-model="appName" :disabled="true" />
        </div>
        <!-- APP Version -->
        <div class="step_agreement">
          <span>{{ prompt.lang_dhop_app_version }}</span>
          <el-input v-model="appVersion" :disabled="true" />
        </div>
        <!-- Device UID -->
        <div class="step_agreement device">
          <span>
            {{ prompt.lang_dhop_device_uid }}
            <span>*</span>
          </span>
          <el-input
            v-model="file"
            :placeholder="prompt.lang_dhop_uuid_desc"
            @input="showValidHidden"
          />
        </div>
        <p class="device_desc" :class="{ step_bottom: showFile == true }">
          <a @click="UUIDAccess">{{ prompt.lang_dhop_access_method }}</a>
          <a @click="UIDTemaplate">{{ prompt.lang_dhop_download_template }}</a>
        </p>
        <p v-if="showFile" class="error error_bottom">
          {{ prompt.lang_error_device_uid }}
        </p>
        <!-- Date Expired -->
        <div class="step_agreement vaild">
          <span>
            {{ prompt.lang_date_expired }}
            <span>*</span>
          </span>
          <el-input v-model="valid" :disabled="true" />
        </div>
        <p class="vaild_desc">{{ prompt.lang_dhop_download_vaild }}</p>

        <!-- target list -->
        <div v-if="app_list_show == '1'" class="step_agreement vaild">
          <span>{{ prompt.lang_dhop_target_list }}</span>
          <el-select
            v-model="custom"
            multiple
            :placeholder="prompt.lang_dhop_target_list"
          >
            <el-option
              v-for="custom in customs"
              :key="custom.id"
              :label="custom.app_name"
              :value="custom.app_name"
            />
          </el-select>
        </div>
        <p v-if="app_list_show == '1'" class="vaild_desc">
          {{ prompt.lang_dhop_download_target }}
        </p>
        <!-- 上传文件按钮 -->
        <el-upload
          ref="upload"
          class="step_create_key"
          :action="uploadUrl"
          :on-change="handleChange"
          :show-file-list="showFileList"
          :on-success="handleSuccess"
          :file-list="fileList"
          @click="upload"
        >
          <button class="dhop_application_file_button" type="button">
            {{ prompt.lang_dhop_batch_import }}
          </button>
        </el-upload>
      </div>
      <!-- 下载按钮 -->
      <div class="step_button">
        <button type="button" class="step_two_next" @click="stepThreeNext">
          {{ prompt.lang_dhop_download_license }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { _axios } from '@/plugins/axios.js'
import {
  versionListApi,
  targetListApi,
  accessDownloadApi,
  dhopLicensesApi,
  licenseDownloadApi
} from '@/api/partnerProgram'
export default {
  data() {
    return {
      loading: false,
      showFile: false,
      showVersion: false,
      appName: '',
      appVersion: '',
      SDKVersion: '',
      versions: [], // DHOP-SDK Version下拉
      custom: '',
      customs: [], // Target List下拉
      app_list_show: '',
      valid: '90',
      showFileList: false,
      file: '',
      uploadUrl: _axios.defaults.baseURL + '/dhop_manager/import',
      fileList: [], // 上传的文件
      download_url: '',
      prompt: {
        lang_dhop_sdk_version: this.LP.lang_dhop_sdk_version,
        lang_dhop_sdk_ersion_access_method: this.LP.lang_dhop_sdk_ersion_access_method,
        lang_error_app_version: this.LP.lang_error_app_version,
        lang_error_device_uid: this.LP.lang_error_device_uid,
        lang_dhop_app_name: this.LP.lang_dhop_app_name,
        lang_dhop_app_version: this.LP.lang_dhop_app_version,
        lang_dhop_device_uid: this.LP.lang_dhop_device_uid,
        lang_dhop_uuid_desc: this.LP.lang_dhop_uuid_desc,
        lang_dhop_access_method: this.LP.lang_dhop_access_method,
        lang_dhop_download_template: this.LP.lang_dhop_download_template,
        lang_date_expired: this.LP.lang_date_expired,
        lang_dhop_target_list: this.LP.lang_dhop_target_list,
        lang_dhop_batch_import: this.LP.lang_dhop_batch_import,
        lang_dhop_download_license: this.LP.lang_dhop_download_license,
        lang_SDK_Version: this.LP.lang_uploads_sdk_version,
        lang_dhop_Device_UUID: this.LP.lang_uploads_device_uuid,
        lang_dhop_CPUID_Template: this.LP.lang_uploads_cpuid_template
      }
    }
  },
  mounted() {
    this.getVsersionList()
  },
  methods: {
    // 获取version下拉接口
    getVsersionList() {
      versionListApi().then(res => {
        this.versions = res.data
        this.getTargetList()
      })
    },
    // 获取Target List下拉接口
    getTargetList() {
      targetListApi().then(res => {
        this.customs = res.data
      })
    },
    // 点击SDK Version access method
    AccessMethod() {
      this.download_url = this.prompt.lang_SDK_Version
      this.getDwonload()
    },
    // 点击Device UUID access method
    UUIDAccess() {
      this.download_url = this.prompt.lang_dhop_Device_UUID
      this.getDwonload()
    },
    // 点击Download Template
    UIDTemaplate() {
      this.download_url = this.prompt.lang_dhop_CPUID_Template
      this.getDwonload()
    },
    // Dwonload
    getDwonload() {
      accessDownloadApi({
        file_url: this.download_url
      }).then(res => {
        location.href = res.data
      })
    },
    // version下拉数据改变
    changeVersion(val) {
      this.showVersion = false
      this.appName = val.app_name
      this.appVersion = val.app_version
      this.SDKVersion = val.dhop_version
      this.app_list_show = val.app_list_show
      this.key = val.public_key
      this.versionId = val.id
      this.versionFile = val.version_file
      this.custom = ''
    },
    // 上传文件
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1)
    },
    // 上传成功
    handleSuccess(response, file, fileList) {
      const data = response.data
      this.file = data
    },
    upload() {},
    // Download License
    stepThreeNext() {
      if (this.SDKVersion === '') {
        this.showVersion = true
      }
      if (this.file === '') {
        this.showFile = true
      } else {
        dhopLicensesApi({
          app_name_id: -1,
          app_version: this.appVersion,
          hardware_id: this.file,
          effective: this.valid,
          targetList: this.custom,
          debug_public_key: this.key,
          version_id: this.versionId,
          version_file: this.versionFile,
          licLevel: 1
        }).then(res => {
          licenseDownloadApi({
            id: res.data.license_id
          }).then(res => {
            window.location.href = res.data
          })
        })
      }
    },
    showValidHidden() {
      this.showFile = false
    }
  }
}
</script>

<style lang="less" scoped>
.debug-enviroment {
  padding: 50px 0;
  .debug-content {
    .step_three_content {
      width: 82%;
      display: inline-block;
      vertical-align: top;
      padding-top: 8px;
      position: relative;
      .step_app_name {
        padding: 18px 0;
        font-size: 16px;
        line-height: 29px;
        letter-spacing: 0px;
        color: #666666;
      }
      .step_agreement {
        width: 100%;
        border: solid 1px #dadada;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 29px;
        letter-spacing: 0px;
        margin-bottom: 20px;
        &:first-child {
          margin-bottom: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        > span {
          display: inline-block;
          padding: 10px 20px;
          &:first-child {
            width: 221px;
            padding: 12px 0;
            text-align: center;
            color: #666666;
            border-right: solid 1px #dadada;
            > span {
              color: #ff0000;
              font-weight: bold;
            }
          }
          &:last-child {
            a {
              text-decoration: underline;
              color: #0e5fac;
            }
          }
        }
        > .el-select,
        > .el-input {
          width: calc(100% - 221px);
        }
      }
      .vaild,
      .device {
        margin-bottom: 0;
      }
      .step_bottom {
        margin-bottom: 0 !important;
      }
      .step_create_key {
        cursor: pointer;
        padding: 1px;
        border-radius: 5px;
        border: solid 1px #0e5fac;
        background-color: #fff;
        position: absolute;
        top: 265px;
        right: -175px;
        button {
          cursor: pointer;
          font-size: 18px;
          color: #0e5fac;
          background-color: #fff;
          padding: 13px 18px;
        }
      }
      .vaild_desc {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 29px;
        letter-spacing: 0px;
        color: #666666;
      }
      .device_desc {
        text-align: right;
        margin-top: 10px;
        margin-bottom: 20px;
        cursor: pointer;
        a {
          text-decoration: underline;
          font-size: 16px;
          color: #0e5fac;
          &:first-child {
            padding-right: 70px;
          }
        }
      }
      .access a {
        padding-right: 0px !important;
      }
    }
    .step_button {
      text-align: center;
      margin-top: 41px;
      .step_two_next {
        cursor: pointer;
        padding: 13px 37px;
        background-color: #0e5fac;
        border-radius: 5px;
        font-size: 18px;
        color: #ffffff;
      }
    }
    .error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
    }
    .error_bottom {
      margin-bottom: 4px;
    }
  }
}
</style>
<style lang="less">
.debug-enviroment {
  .el-input__inner {
    border: none;
    height: 55px;
    font-size: 16px;
  }
  .el-radio__label {
    font-size: 16px;
  }
}
</style>
