<template>
  <div class="dhop-manager">
    <public-banner :banner-info="bannerInfo" />
    <div class="manager-content container">
      <div class="content-box">
        <div class="content-title">{{ LP.lang_sdk_reference }}</div>
        <div class="download-title">
          <a class="link" @click="handleDownload('SDKDownload')">
            <span class="title">{{ LP.lang_sdk_files_download }}</span>
            <span class="iconfont icon-a-xiala1x"></span>
          </a>
        </div>
        <div class="download-title">
          <a class="link" @click="handleDownload('Debug')">
            <span class="title">{{ LP.lang_debug_enviroment }}</span>
            <span class="iconfont icon-a-xiala1x"></span>
          </a>
        </div>
      </div>
      <div class="content-box">
        <div class="content-title">{{ LP.lang_license_management }}</div>
        <div class="download-title">
          <a class="link" @click="handleDownload('LicenseInfo')">
            <span class="title">{{ LP.lang_license_info }}</span>
            <span class="iconfont icon-a-xiala1x"></span>
          </a>
        </div>
        <div class="download-title">
          <a class="link" @click="handleDownload('KeyInfo')">
            <span class="title">{{ LP.lang_rsa_key_info }}</span>
            <span class="iconfont icon-a-xiala1x"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { getLanguageApi } from '@/api/home/'
import { mapActions } from 'vuex'
export default {
  components: {
    PublicBanner
  },
  data() {
    return {
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      },
      // 存放各栏目的背景图
      titleImg: {
        SDKImg: '',
        licenseImg: ''
      }
    }
  },
  created() {
    this.getBanner()
    this.getTitleImg()
  },
  methods: {
    // 跳转
    handleDownload(url) {
      this.$router.push('DHOPManager/' + url)
    },
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 22 })
      this.bannerInfo.title = data.data.img_title
      this.bannerInfo.img = data.data.img_image
    },
    // 获取各栏目的背景图
    getTitleImg() {
      getLanguageApi().then(res => {
        this.titleImg.SDKImg = res.data.lang_sdk_reference_image
        this.titleImg.licenseImg = res.data.lang_license_management_image
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
  .dhop-manager {
    .manager-content {
      max-width: 1280px;
      margin: 72px auto;
      display: flex;
      .content-box {
        width: 100%;
        height: 304px;
        margin-right: 32px;
        border-radius: 2px;
        border: 1px solid #E4E7ED;
        &:last-child {
          margin-right: 0px;
        }
        .content-title {
          line-height: 160px;
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          color: #FFFFFF;
					background: linear-gradient(180deg, #5FABF2 0%, #0E5FAC 100%);
        }
				.download-title{
					padding: 24px 46px 23px 53px;
					font-size: 18px;
					font-weight: 600;
					color: #303133;
					line-height: 24px;
					border-bottom: 1px solid #E4E7ED;
					.link{
						display: flex;
						justify-content: space-between;
						align-items: center;
						.iconfont{
							font-size: 13px;
							height: 18px;
							width: 18px;
							line-height: 21px;
							text-align: center;
							border-radius: 50%;
							transform: rotate(-90deg);
							background-color: @theme;
							color: #fff;
						}
						&:hover{
							cursor: pointer;
						}
					}
					&:last-child{
						border-bottom: none;
					}
				}
      }
    }
		@media screen and (max-width:768px) {
			.manager-content{
				flex-direction: column;
				margin: 24px auto;
				.content-box{
					margin-right: 0;
					margin-bottom: 24px;
					.download-title{
						padding: 26px 23px 24px 17px;
						font-size: 16px;
						line-height: 21px;
					}
				}
			}
		}
  }
</style>
