<template>
  <div class="solutions-wrap">
    <public-banner :is-search="true" :banner-info="bannerInfo" @search="handleSearch" />
    <!-- Filter by -->
    <div class="filter-by-box container">
      <div class="filter-by-title">
        <a class="link" @click="handleFold">
          <span class="title">{{ information.lang_filter_by }}</span>
          <span class="iconfont icon-a-xiala1x" :class="{ default: isDefault }"></span>
        </a>
      </div>
      <!-- 完整数据 -->
      <ul v-if="isDefault" class="filter-by-list">
        <li v-for="(item, index) in labelList" :key="index" class="filter-by-item">
          <h4 class="list-title">{{ item.menu_name }}:</h4>
          <ul class="child-list">
            <li class="child-item">
              <label-check
                v-model="item.value"
                :options="item.label_list"
                :prop="{ id: 'id', label: 'name' }"
              />
            </li>
          </ul>
        </li>
      </ul>
      <!-- 选中数据 -->
      <div v-else class="default-list">
        <label-check
          v-model="defaultList"
          :options="defaultList"
          :prop="{ id: 'id', label: 'name' }"
        />
      </div>
      <div v-if="!isMobile || isDefault" class="filter-by-btn">
        <el-button type="primary" @click="handleScreen">{{ information.lang_dipp_search }}</el-button>
        <el-button type="info" @click="handleReset">{{ information.lang_reset }}</el-button>
      </div>
    </div>
    <div class="solutions-list">
      <ul class="list container">
        <li v-for="(item, index) in information.result_info" :key="index" class="list-item">
          <router-link :to="{path:'applicationMarketplace/subPage', query:{id: item.id}}" class="link">
            <div class="img-box">
              <div
                class="img-bg"
                :style="`background: url(${item.image}) center center / contain no-repeat`"
              ></div>
            </div>
            <div class="text-box">
              <h4 class="title ellipsis">{{ item.application_name }}</h4>
              <p class="description">{{ item.description }}</p>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="public-pagination container">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :total="information.count"
          :page-size="information.limit"
          :pager-count="5"
          @current-change="handlePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import publicBanner from '@/components/public/PublicBanner'
import LabelCheck from '@/components/myPage/LabelCheck'
import { applicationListpApi } from '@/api/partnerProgram'
import { mapActions } from 'vuex'
export default {
  components: {
    publicBanner,
    LabelCheck
  },
  data() {
    return {
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      },
      information: {}, //  总数据
      keywords: '', // 搜索关键字
      isMobile: false, // 是否为移动端
      isDefault: true, // 默认展开
      defaultList: [], // label选中数据
      labelList: [], // label完整数据
      labelId: [], // label完整数据/选中的集合
      labelIdSelected: [] // label选中数据/选中的集合
    }
  },
  created() {
    this.getBanner()
  },
  mounted() {
    applicationListpApi().then((res) => {
      this.information = res.data
      this.labelList = res.data.search_data
      this.labelList.forEach((item, index) => {
        this.$set(item, 'value', [])
      })
    })
    //  判断页面刷新时屏幕宽度是否为小屏
    var windowWidth = document.documentElement.clientWidth
    if (windowWidth <= 768) {
      this.isDefault = false
      this.isMobile = true
    } else {
      this.isDefault = true
    }
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 72 })
      this.bannerInfo.img = data.data.img_image
    },
    // 解决方案列表接口封装
    getList(page, solutionName, menu_id) {
      applicationListpApi({
        application_name: solutionName,
        page: page,
        menu_id: menu_id
      }).then((res) => {
        this.information = res.data
      })
    },
    // 展开折叠
    handleFold() {
      this.isDefault = !this.isDefault
      if (this.isDefault) {
        // 当前展开
        this.labelList.forEach(item => {
          for (let i = item.value.length - 1; i >= 0; i--) {
            if (this.defaultList.every(s => s.id !== item.value[i].id)) {
              item.value.splice(i, 1)
            }
          }
        })
      } else {
        // 当前合拢
        let _arr = []
        this.labelList.forEach(item => {
          _arr = [..._arr, ...item.value]
        })
        this.defaultList = _arr
      }
    },
    // 搜索
    handleSearch(keywords) {
      this.keywords = keywords
      this.getList(1, keywords)
    },
    // 筛选搜索
    handleScreen() {
      this.labelId = []
      this.labelIdSelected = []
      this.labelList.forEach(item => {
        item.value.forEach(t => {
          this.labelId.push(t.id)
        })
      })
      this.defaultList.forEach(item => {
        this.labelIdSelected.push(item.id)
      })
      this.getList(1, this.keywords, this.isDefault ? this.labelId : this.labelIdSelected)
    },
    // 清空筛选
    handleReset() {
      this.defaultList = []
      this.labelList.forEach((item, index) => {
        this.$set(item, 'value', [])
      })
      this.getList(1, this.keywords)
    },
    // 分页
    handlePage(page) {
      this.getList(page, this.keywords, this.isDefault ? this.labelId : this.labelIdSelected)
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/common/common";
.solutions-wrap {
  .filter-by-box {
    padding: 20px 0 40px 0;
    background-color: #ffffff;
		z-index: 2;
    .filter-by-title {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .link {
        cursor: pointer;
        .title {
          font-size: 24px;
          font-weight: bold;
          color: #303133;
          line-height: 32px;
          margin-right: 16px;
          display: inline-block;
        }
        .iconfont {
          display: inline-block;
          font-size: 24px;
          color: #979797;
          transform: rotate(0);
          .transition();
          &.default {
            transform: rotate(180deg);
          }
        }
      }
    }
    .filter-by-list {
      .filter-by-item {
        display: flex;
        & + .filter-by-item {
          margin-top: 8px;
        }
        .list-title {
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          flex-shrink: 0;
        }
        .child-list {
          display: flex;
          flex-wrap: wrap;
          margin-left: 24px;
        }
      }
    }
    .default-list {
      /deep/ .options-wrap {
        .option-item {
          border-color: @theme;
          .label-text {
            color: @theme;
          }
        }
      }
    }
    .filter-by-btn {
      text-align: center;
      margin-top: 8px;
    }
  }
  .solutions-list {
    padding: 48px 0;
    background: #f4f4f5;
    .list {
      display: flex;
      flex-wrap: wrap;
      .list-item {
        width: calc(33.333333% - 11px);
        margin: 0 16px 16px 0;
        background: #ffffff;
        border: 1px solid #e4e7ed;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:hover{
          .title, .description{
            color: @theme !important;
          }
        }
        .link {
          display: block;
          .img-box {
            overflow: hidden;
            &:hover{
              .img-bg{
                .scale();
              }
            }
            height: 237px;
            .img-bg {
              .transition();
              height: 237px;
            }
          }
          .text-box {
            padding: 20px;
            border-top: 1px solid #e4e7ed;
            .title {
              font-size: 18px;
              font-weight: 600;
              color: #303133;
              line-height: 24px;
              margin-bottom: 10px;
            }
            .description {
              font-size: 16px;
              color: #606266;
              line-height: 21px;
              height: 65px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            /deep/ .options-wrap {
              .option-item {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width:768px) {
    .filter-by-box{
      padding: 14px 20px;
      width: 100%;
      position: sticky;
      top: 60px;
      .shadow();
      .filter-by-title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 10px;
        .link{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title{
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;
          }
        }
      }
      .filter-by-list {
        margin-top: 20px;
        .filter-by-item {
          display: flex;
          flex-direction: column;
          .list-title {
            font-size: 14px;
            font-weight: bold;
            line-height: 19px;
          }
          .child-list {
            margin-left: 0px;
            margin-top: 10px;
          }
        }
      }
    }
    .solutions-list{
      padding: 24px 0;
      .list {
        .list-item {
          width: 100%;
          margin: 0 0 16px 0;
          .link{
            .img-box{
              height: 190px;
              .img-bg{
                height: 190px;
              }
            }
            .text-box {
              padding: 14px 16px 26px;
            }
          }
        }
      }
    }
		/deep/.public-pagination{
		.el-pagination__jump{
			display: block;
			margin: 16px 0 0 0;
		}
	}
  }
}
</style>

<style lang="less">
.solutions-wrap {
  .public-label {
    margin: 0 8px 8px 0;
    .label-text {
      font-size: 16px;
      font-size: 16px;
      line-height: 21px;
    }
  }
}
</style>
