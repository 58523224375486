<template>
  <div v-loading="loading" class="step_three" element-loading-text="loading...">
    <template>
      <span class="step_line_title">{{ prompt.lang_step_one }}</span>
      <span class="step_line_title">{{ prompt.lang_step_two }}</span>
      <span class="step_line_title step_three step_disc">{{
        prompt.lang_step_three
      }}</span>
      <div class="step_line">
        <div class="circle">
          <div class="step_circle"></div>
          <div class="step_circle"></div>
          <div class="step_circle disc"></div>
        </div>
      </div>
      <span class="step_line_title_bottom">{{
        prompt.lang_create_app_name
      }}</span>
      <span class="step_line_title_bottom">{{
        prompt.lang_step_two_title
      }}</span>
      <span class="step_line_title_bottom step_three_title step_disc">{{
        prompt.lang_step_three_title
      }}</span>
    </template>
    <div class="step_three_content">
      <!-- <span class="step_step">{{ prompt.lang_step_three }}</span> -->
      <!-- 单选组 -->
      <div class="step_app_name">
        <el-radio-group v-model="reverse" @change="changeRadio">
          <el-radio label="Development License" />
          <el-radio label="Distribution License" />
        </el-radio-group>
      </div>
      <!-- App Name -->
      <div class="step_agreement">
        <span>
          {{ prompt.lang_dhop_app_name }}
          <span>*</span>
        </span>
        <el-input v-model="appName" :disabled="true" />
      </div>
      <!-- Key Name -->
      <div class="step_agreement">
        <span>
          {{ prompt.lang_dhop_key_name }}
          <span>*</span>
        </span>
        <el-input v-model="keyName" :disabled="true" />
      </div>

      <!-- App Version -->
      <div class="step_agreement">
        <span>
          {{ prompt.lang_dhop_app_version }}
          <span>*</span>
        </span>
        <el-input v-model="appVersion" @input="hiddenError" />
      </div>
      <p v-if="showVersion" class="error error_bottom">
        {{ prompt.lang_error_app_version }}
      </p>
      <!-- Device UID -->
      <div class="step_agreement device">
        <span>{{ prompt.lang_dhop_device_uid }}</span>
        <el-input v-model="file" :placeholder="prompt.lang_dhop_uuid_desc" />
      </div>
      <p class="device_desc">
        <a @click="UUIDAccess">{{ prompt.lang_dhop_access_method }}</a>
        <a @click="UIDTemaplate">{{ prompt.lang_dhop_download_template }}</a>
      </p>
      <!-- Vaild days -->
      <div class="step_agreement vaild" :class="{ step_bottom: showValidDay }">
        <span>
          {{ prompt.lang_date_expired }}
          <span>*</span>
        </span>
        <el-input
          v-model="valid"
          :disabled="disabled"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          maxlength="5"
          @input="showValidHidden"
        />
      </div>
      <p v-if="showValidDay" class="error error_bottom">
        {{ prompt.lang_dhop_app_name_cannot_be_empty }}
      </p>
      <p v-if="reverseRadio === 'Distribution'" class="vaild_desc">{{ prompt.lang_dhop_vaild_desc }}</p>
      <p v-else class="vaild_desc">{{ prompt.lang_dhop_download_vaild }}</p>
      <!-- 上传模板按钮 -->
      <el-upload
        ref="upload"
        class="step_create_key"
        :action="uploadUrl"
        :on-change="handleChange"
        :show-file-list="showFileList"
        :on-success="handleSuccess"
        :file-list="fileList"
        @click="upload"
      >
        <button class="dhop_application_file_button" type="button">
          {{ prompt.lang_dhop_batch_import }}
        </button>
      </el-upload>
    </div>
    <!-- 返回上一步、提交按钮 -->
    <div class="step_button">
      <button
        type="button"
        class="step_two_previous"
        @click="stepThreePrevious"
      >
        {{ prompt.lang_dhop_previous }}
      </button>
      <button type="button" class="step_two_next" @click="stepThreeNext">
        {{ prompt.lang_dhop_apply_for_license }}
      </button>
    </div>
  </div>
</template>

<script>
import { _axios } from '@/plugins/axios.js'
import { licenseListApi, accessDownloadApi, dhopLicensesApi } from '@/api/partnerProgram'
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: 0
    },
    customShow: {
      type: Number,
      default: 0
    },
    appIds: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      prompt: {
        lang_step_one: this.LP.lang_step_one,
        lang_step_two: this.LP.lang_step_two,
        lang_step_three: this.LP.lang_step_three,
        lang_create_app_name: this.LP.lang_create_app_name,
        lang_step_two_title: this.LP.lang_step_two_title,
        lang_step_three_title: this.LP.lang_dhop_apply_for_license,
        lang_dhop_app_name: this.LP.lang_dhop_app_name,
        lang_dhop_key_name: this.LP.lang_dhop_key_name,
        lang_dhop_app_version: this.LP.lang_dhop_app_version,
        lang_error_app_version: this.LP.lang_error_app_version,
        lang_dhop_device_uid: this.LP.lang_dhop_device_uid,
        lang_dhop_uuid_desc: this.LP.lang_dhop_uuid_desc,
        lang_dhop_access_method: this.LP.lang_dhop_access_method,
        lang_dhop_download_template: this.LP.lang_dhop_download_template,
        lang_date_expired: this.LP.lang_date_expired,
        lang_dhop_app_name_cannot_be_empty: this.LP.lang_dhop_app_name_cannot_be_empty,
        lang_dhop_vaild_desc: this.LP.lang_dhop_vaild_desc,
        lang_dhop_download_vaild: this.LP.lang_dhop_download_vaild,
        lang_dhop_batch_import: this.LP.lang_dhop_batch_import,
        lang_dhop_previous: this.LP.lang_dhop_previous,
        lang_dhop_apply_for_license: this.LP.lang_step_three_title,
        lang_dhop_Device_UUID: this.LP.lang_dhop_Device_UUID,
        lang_dhop_CPUID_Template: this.LP.lang_dhop_CPUID_Template,
        lang_dhop_apply_success: this.LP.lang_dhop_apply_success,
        lang_dhop_ok: this.LP.lang_dhop_ok
      },
      reverse: 'Development License',
      reverseRadio: '',
      showValidDay: false,
      showVersion: false,
      appName: '',
      keyName: '',
      appVersion: '',
      valid: '90',
      custom: '',
      test: -1,
      num: 2,
      baseURL: process.env.VUE_APP_BASE_API,
      uploadUrl: _axios.defaults.baseURL + '/dhop_manager/import',
      fileList: [],
      showFileList: false,
      file: '',
      information: {},
      disabled: true,
      loading: true,
      public_key: ''
    }
  },
  created() {
    licenseListApi({
      category: '0',
      type: '1',
      app_id: this.appIds
    }).then(res => {
      this.appName = res.data.app_name
      this.information = res.data
      this.keyName = res.data.key_name
      this.public_key = res.data.public_key
      this.loading = false
    })
  },
  methods: {
    stepThreePrevious() {
      this.$emit('threePrevious', this.num, this.keyName, this.public_key)
    },
    // Apply for License
    stepThreeNext() {
      if (this.appVersion === '') {
        this.showVersion = true
        return
      }
      if (this.valid === '') {
        this.showValidDay = true
      } else {
        dhopLicensesApi({
          type: this.reverseRadio,
          app_name_id: this.information.app_id,
          key_name_id: this.information.key_id,
          app_version: this.appVersion,
          hardware_id: this.file,
          effective: this.valid,
          custom_Field: this.custom,
          licLevel: 0
        }).then(res => {
          this.$alert(this.prompt.lang_dhop_apply_success, {
            confirmButtonText: this.prompt.lang_dhop_ok,
            type: 'success',
            callback: (action) => {
              this.$emit('threeNext', this.test)
              localStorage.removeItem('customShow')
              this.$router.go(0)
            }
          })
        })
      }
    },
    changeRadio(val) {
      if (val === 'Distribution License') {
        this.reverseRadio = 'Distribution'
        this.disabled = false
        this.valid = ''
      } else {
        this.reverseRadio = 'Development'
        this.disabled = true
        this.valid = '90'
      }
    },
    showValidHidden() {
      this.showValidDay = false
      if (this.valid > 10800) {
        this.valid = 10800
      }
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1)
    },
    handleSuccess(response, file, fileList) {
      const data = response.data
      this.file = data
    },
    hiddenError() {
      this.showVersion = false
    },
    UUIDAccess() {
      this.download_url = this.prompt.lang_dhop_Device_UUID
      this.Dwonload()
    },
    UIDTemaplate() {
      this.download_url = this.prompt.lang_dhop_CPUID_Template
      this.Dwonload()
    },
    Dwonload() {
      accessDownloadApi({
        file_url: this.download_url
      }).then(res => {
        location.href = res.data
      })
    },
    upload() {}
  }
}
</script>

<style lang="less" scoped>
.step_three {
  .step_line_title {
    display: inline-block;
    width: 226px;
    padding-left: 88px;
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    &:first-child {
      padding-left: 40px;
    }
  }
  .step_line_title_bottom {
    display: inline-block;
    width: 226px;
    padding-left: 10px;
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    &:last-child {
      padding-left: 50px;
    }
  }
  .step_three_title {
    padding-left: 95px;
  }
  .step_three {
    padding-left: 135px;
  }
  .step_disc {
    color: #0e5fac;
  }
  .step_line {
    width: 680px;
    border-top: solid 1px #0e5fac;
    position: relative;
    margin: 15px 0px;
    .circle {
      position: absolute;
      left: -8px;
      top: -10px;
      .step_circle {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: solid 1px #0e5fac;
        background-color: #fff;
        margin-left: 257px;
        &:first-child {
          margin-left: 59px;
        }
      }
      .disc {
        background: #0e5fac;
      }
    }
  }
  .step_three_content {
    width: 82%;
    display: inline-block;
    vertical-align: top;
    margin-left: 26px;
    padding-top: 8px;
    position: relative;
    .step_step {
      font-size: 18px;
      font-weight: bold;
      color: #0e5fac;
    }
    .step_app_name {
      padding: 18px 0;
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0px;
      color: #666666;
    }
    .step_agreement {
      width: 100%;
      border: solid 1px #dadada;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      > span {
        display: inline-block;
        padding: 10px 20px;
        &:first-child {
          width: 221px;
          padding: 12px 0;
          text-align: center;
          color: #666666;
          border-right: solid 1px #dadada;
          > span {
            color: #ff0000;
            font-weight: bold;
          }
        }
        &:last-child {
          a {
            text-decoration: underline;
            color: #0e5fac;
          }
        }
      }
      > .el-input {
        width: calc(100% - 221px);
      }
    }
    .vaild,
    .device {
      margin-bottom: 0;
    }
    .step_bottom {
      margin-bottom: 0;
    }
    .step_create_key {
      cursor: pointer;
      padding: 1px;
      border-radius: 5px;
      border: solid 1px #0e5fac;
      background-color: #fff;
      position: absolute;
      top: 307px;
      right: -175px;
      button {
        cursor: pointer;
        font-size: 18px;
        color: #0e5fac;
        background-color: #fff;
        padding: 13px 18px;
      }
    }
    .vaild_desc {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      color: #666666;
    }
    .device_desc {
      text-align: right;
      margin-bottom: 20px;
      a {
        text-decoration: underline;
        font-size: 16px;
        color: #0e5fac;
        cursor: pointer;
        &:first-child {
          padding-right: 70px;
        }
      }
    }
  }
  .step_button {
    text-align: center;
    margin-top: 41px;
    .step_two_previous {
      cursor: pointer;
      padding: 15px 65px;
      margin-right: 40px;
      border-radius: 5px;
      border: solid 1px #dadada;
      background-color: #fff;
      font-size: 18px;
      color: #666666;
    }
    .step_two_next {
      cursor: pointer;
      padding: 13px 37px;
      background-color: #0e5fac;
      border-radius: 5px;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .error {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
  .error_bottom {
    margin-bottom: 4px;
  }
}
</style>
<style lang="less">
.step_three {
  .el-input__inner {
    border: none;
    height: 55px;
    font-size: 16px;
  }
  .el-radio__label {
    font-size: 16px;
  }
}
</style>
