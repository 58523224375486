<template>
  <div v-loading="loading" class="key_info" element-loading-text="loading...">
    <div class="container">
      <BreadCrumb :type="crumb" />
      <p class="key_info_list_title">
        <i class="iconfont icon-chuangjiandanju" @click="create"></i>
        <span @click="create">{{ prompt.lang_dhop_create }}</span>
      </p>
      <div v-if="infoList && infoList.length > 0" class="key_info_list">
        <p>{{ prompt.lang_dhop_key_name }}</p>
        <p>{{ prompt.lang_dhop_generate_date }}</p>
        <p>{{ prompt.lang_dhop_opreation }}</p>
      </div>
      <div
        v-for="(item, index) in infoList"
        :key="index"
        class="key_info_list_children"
      >
        <span>{{ item.key_name }}</span>
        <span>{{ item.time }}</span>
        <p>
          <span @click="edit(item.id)">{{ prompt.lang_dhop_edit }}</span>
          <span @click="infoDetele(item.id)">{{ prompt.lang_dhop_delete }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/partnerProgram/BreadCrumb'
import { licenseKeyListApi, dhopDeteleApi } from '@/api/partnerProgram'
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      prompt: {
        lang_dhop_create: this.LP.lang_dhop_create,
        lang_dhop_key_name: this.LP.lang_dhop_key_name,
        lang_dhop_generate_date: this.LP.lang_dhop_generate_date,
        lang_dhop_opreation: this.LP.lang_dhop_opreation,
        lang_dhop_edit: this.LP.lang_dhop_edit,
        lang_dhop_delete: this.LP.lang_dhop_delete,
        lang_dhop_delete_success: this.LP.lang_dhop_delete_success,
        lang_dhop_ok: this.LP.lang_dhop_ok,
        lang_dhop_detele_prompt: this.LP.lang_dhop_detele_prompt,
        lang_dhop_detele_title: this.LP.lang_dhop_detele_title
      },
      infoList: [],
      breadList: [],
      crumb: '',
      loading: false
    }
  },
  created() {
    this.crumb = 'key_info'
    licenseKeyListApi({
      type: 'key_info'
    }).then(res => {
      this.infoList = res.data
    })
  },
  methods: {
    infoDetele(id) {
      this.$confirm(
        this.prompt.lang_dhop_detele_prompt,
        this.prompt.lang_dhop_detele_title,
        {
          type: 'warning'
        }
      )
        .then(() => {
          dhopDeteleApi({
            id: id,
            type: 'key_name'
          }).then(res => {
            this.$alert(this.prompt.lang_dhop_delete_success, {
              confirmButtonText: this.prompt.lang_dhop_ok,
              type: 'success',
              callback: (action) => {
                licenseKeyListApi({
                  type: ''
                }).then(res => {
                  this.infoList = res.data
                })
              }
            })
          })
        })
        .catch(() => {
        })
    },
    // 创建
    create() {
      this.$router.push({
        name: 'CreateKey',
        query: {
          status: 'add'
        }
      })
    },
    edit(id) {
      this.$router.push({
        name: 'CreateKey',
        query: {
          status: 'update',
          id: id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.key_info {
  margin-top: 50px;
  .key_info_list_title {
    text-align: center;
    color: #0e5fac;
    i {
      cursor: pointer;
      font-size: 24px;
      vertical-align: middle;
    }
    span {
      cursor: pointer;
      text-decoration: underline;
      font-size: 14px;
    }
  }
  .key_info_list {
    margin-top: 60px;
    padding-bottom: 10px;
    border-bottom: solid 2px #dadada;
    p {
      display: inline-block;
      width: 30%;
      text-align: center;
      &:last-child {
        width: 40%;
      }
    }
  }
  .key_info_list_children {
    padding: 20px 0;
    border-bottom: solid 1px #dadada;
    & > span {
      display: inline-block;
      width: 30%;
      text-align: center;
    }
    p {
      display: inline-block;
      width: 40%;
      text-align: center;
      span {
        padding: 8px 30px;
        border-radius: 20px;
        border: solid 1px #0e5fac;
        font-size: 16px;
        font-weight: bold;
        color: #0e5fac;
        cursor: pointer;
        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.key_info {
  .el-input__inner {
    padding-right: 0;
    margin-left: 120px;
    border: none;
    max-width: 190px;
  }
  .el-icon-caret-bottom {
    color: #0e5fac;
  }
}
</style>
