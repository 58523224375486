<template>
  <div v-loading="loading" class="step_two" element-loading-text="loading...">
    <template>
      <span class="step_line_title">{{ prompt.lang_step_one }}</span>
      <span class="step_line_title step_disc">{{ prompt.lang_step_two }}</span>
      <span class="step_line_title step_three">{{
        prompt.lang_step_three
      }}</span>
      <div class="step_line">
        <div class="circle">
          <div class="step_circle"></div>
          <div class="step_circle disc"></div>
          <div class="step_circle"></div>
        </div>
      </div>
      <span class="step_line_title_bottom">{{
        prompt.lang_create_app_name
      }}</span>
      <span class="step_line_title_bottom step_disc">{{
        prompt.lang_step_two_title
      }}</span>
      <span class="step_line_title_bottom step_three_title">{{
        prompt.lang_step_three_title
      }}</span>
    </template>
    <div class="step_two_content">
      <p class="step_app_name" v-html="prompt.lang_step_two_content"></p>
      <div class="step_agreement">
        <span>{{ prompt.lang_key_list }}</span>
        <el-select v-model="name" :placeholder="prompt.lang_key_list">
          <el-option
            v-for="item in keyList"
            :key="item.id"
            :label="item.key_name"
            :value="item.id"
          />
        </el-select>
      </div>
      <!-- Key Name -->
      <template v-if="name === 0">
        <div
          class="step_agreement"
          :class="{ step_bottom: showKeyName || sameNameShow }"
        >
          <span>
            {{ prompt.lang_dhop_key_name }}
            <span>*</span>
          </span>
          <el-input v-model="keyName" @input="errorHidden" />
        </div>
        <p v-if="showKeyName" class="error error_bottom">
          {{ prompt.lang_dhop_key_name_cannot_be_empty }}
        </p>
        <p v-if="sameNameShow" class="error error_bottom">
          {{ same_name }}
        </p>
      </template>
      <!-- Key Info -->
      <template v-if="name === 0">
        <div class="step_agreement_key">
          <span class="step_key_info">
            {{ prompt.lang_dhop_key_inof }}
            <span>*</span>
          </span>
          <el-input
            v-model="keyInfo"
            type="textarea"
            :placeholder="
              tArr[0] + '\n' + tArr[1] + '\n' + tArr[2] + '\n' + tArr[3]
            "
            @input="inputHidden"
          />
        </div>
        <p v-if="showKeyInfo" class="error">{{ errorInformation }}</p>
        <button type="button" class="step_create_key" @click="createKey">
          {{ prompt.lang_dhop_create_key_pair }}
        </button>
        <p class="dowmload_document" @click="downloadDocument">
          {{ prompt.lang_dhop_about_rsa_key }}
        </p>
      </template>
    </div>
    <!-- 返回、下一步 按钮 -->
    <div class="step_button">
      <button type="button" class="step_two_previous" @click="stepTwoPrevious">
        {{ prompt.lang_dhop_previous }}
      </button>
      <button type="button" class="step_two_next" @click="stepTwoNext">
        {{ prompt.lang_dhop_next }}
      </button>
    </div>
    <!-- 成功创建Key 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span class="dialog_span">{{ keyInformation }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="publicKey == 1" @click="dialogVisible = false">
          {{ prompt.lang_cancel }}
        </el-button>
        <el-button type="primary" @click="download">{{ confirm }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { accessDownloadApi } from '@/api/partnerProgram'
import {
  licenseListApi,
  licenseKeyListApi,
  licenseRsaApi,
  licenseCheackRsaApi,
  licenseKeyApi
} from '@/api/partnerProgram'
export default {
  props: {
    appIds: {
      type: Number,
      default: 0
    },
    keyNames: {
      type: String,
      default: ''
    },
    Keys: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      prompt: {
        lang_step_one: this.LP.lang_step_one,
        lang_step_two: this.LP.lang_step_two,
        lang_step_three: this.LP.lang_step_three,
        lang_create_app_name: this.LP.lang_create_app_name,
        lang_step_two_title: this.LP.lang_step_two_title,
        lang_step_three_title: this.LP.lang_dhop_apply_for_license,
        lang_step_two_content: this.LP.lang_step_two_content,
        lang_key_list: this.LP.lang_key_list,
        lang_dhop_key_name: this.LP.lang_dhop_key_name,
        lang_dhop_key_name_cannot_be_empty: this.LP.lang_dhop_key_name_cannot_be_empty,
        lang_dhop_key_inof: this.LP.lang_dhop_key_inof,
        lang_dhop_create_key_pair: this.LP.lang_dhop_create_key_pair,
        lang_dhop_about_rsa_key: this.LP.lang_dhop_about_rsa_key,
        lang_dhop_previous: this.LP.lang_dhop_previous,
        lang_dhop_next: this.LP.lang_dhop_next,
        lang_dhop_please_enter_key: this.LP.lang_dhop_please_enter_key,
        lang_dhop_key_more_five: this.LP.lang_dhop_key_inof_name_cannot_be_empty,
        lang_dhop_ok: this.LP.lang_dhop_ok,
        lang_cancel: this.LP.lang_cancel,
        lang_dhop_copy_download: this.LP.lang_dhop_copy_download,
        lang_dhop_step_two_success: this.LP.lang_dhop_step_two_success,
        lang_dhop_step_two_error: this.LP.lang_dhop_step_two_error,
        lang_dhop_about_rsa_key_file: this.LP.lang_dhop_about_rsa_key_file
      },
      // company: "",
      keyName: '',
      keyInfo: '',
      keyInfoLink: '',
      fileName: '',
      test: 3,
      num: 1,
      tArr: [],
      showKeyName: false,
      showKeyInfo: false,
      errorInformation: '',
      keyInformation: '',
      confirm: '',
      keyId: -1,
      appId: -1,
      stepName: -1,
      changeInput: -1,
      publicKey: -1,
      dialogVisible: false,
      options: [],
      keyList: [],
      oldKeyId: '',
      name: 0,
      keyState: '',
      loading: true,
      sameNameShow: false,
      same_name: '',
      app_name: '',
      step: ''
    }
  },
  computed: {
    // ...mapState({
    //   myProfile: (state) => state.myPage.myProfile
    // })
  },
  async created() {
    this.keyName = this.keyNames
    this.keyInfo = this.Keys
    this.tArr = this.prompt.lang_dhop_please_enter_key.split('\\n')
    await licenseListApi({
      category: '0',
      type: '1',
      app_id: this.appIds
    }).then(res => {
      this.app_name = res.data.app_name
      this.keyName = res.data.key_name
      this.keyInfo = res.data.public_key
      this.stepName = res.data.step
      this.keyId = +res.data.key_id
      this.appId = +res.data.app_id
      this.keyState = res.data.key_state
      this.step = res.data.step
      // 1 为 +created下拉选项内容
      if (res.data.key_state === '0' && this.keyNames !== '') {
        this.name = 0
      } else {
        this.name = res.data.key_id
        this.keyName = ''
        this.keyInfo = ''
      }
    })
    await licenseKeyListApi({
      type: 1
    }).then(res => {
      if (res.data.length > 1) {
        this.keyList = res.data
        if (this.step === '1') {
          this.name = res.data[1].id
        }
      } else {
        this.name = res.data[0].id
        this.keyList = res.data
      }
      this.loading = false
    })
  },
  methods: {
    download() {
      if (this.publicKey === 1) {
        location.href = this.keyInfoLink
      }
      this.dialogVisible = false
    },
    errorHidden() {
      this.showKeyName = false
      this.sameNameShow = false
      this.changeInput = 1
    },
    inputHidden() {
      this.showKeyInfo = false
      this.changeInput = 1
    },
    stepTwoPrevious() {
      this.$emit('twoPrevious', this.num, this.app_name, this.appId)
    },
    // 下一步
    stepTwoNext() {
      if (this.name === 0) {
        if (this.keyName === '') {
          this.showKeyName = true
        }
        if (this.keyInfo === '') {
          this.showKeyInfo = true
          this.errorInformation = this.prompt.lang_dhop_key_inof_name_cannot_be_empty
        } else {
          if (this.stepName === 2 && this.changeInput === 1) {
            // 验证秘钥
            licenseCheackRsaApi({
              public_key: this.keyInfo
            }).then(res => {
              licenseKeyApi({
                type: this.keyState === '1' ? 'add' : 'update',
                menu: 'license_info',
                app_id: this.appId,
                key_id: this.keyId,
                key_name: this.keyName,
                public_key: this.keyInfo,
                select_type: 1
              }).then(res => {
                if (res.message !== 'OK') {
                  this.sameNameShow = true
                  this.same_name = res.message
                } else {
                  this.$emit('twoNext', this.test)
                }
              })
                .catch(() => {
                  this.showKeyInfo = true
                  this.errorInformation = this.prompt.lang_dhop_correct_public_key
                })
            })
          } else if (this.stepName === 2) {
            this.$emit('twoNext', this.test)
          } else {
            licenseCheackRsaApi({
              public_key: this.keyInfo
            }).then(res => {
              licenseKeyApi({
                type: 'add',
                menu: 'license_info',
                app_id: this.appId,
                key_id: this.oldKeyId,
                key_name: this.keyName,
                public_key: this.keyInfo,
                select_type: 1
              }).then(res => {
                if (res.message !== 'OK') {
                  this.sameNameShow = true
                  this.same_name = res.message
                } else {
                  this.$emit('twoNext', this.test)
                  this.$router.push({
                    name: 'LicenseInfo'
                  })
                }
              })
                .catch(() => {
                  this.showKeyInfo = true
                  this.errorInformation = this.prompt.lang_dhop_correct_public_key
                })
            })
          }
        }
      } else {
        licenseKeyApi({
          type: 'update',
          app_id: this.appId,
          key_id: this.name
        }).then(res => {
          this.$emit('twoNext', this.test)
          this.$router.push({
            name: 'LicenseInfo'
          })
        })
      }
    },
    createKey() {
      if (this.keyName === '') {
        this.showKeyName = true
      } else {
        // 生成秘钥
        licenseRsaApi({
          key_name: this.keyName
        }).then(res => {
          if (res.data === '') {
            this.$alert(this.prompt.lang_dhop_key_more_five, {
              confirmButtonText: this.prompt.lang_dhop_ok,
              type: 'error'
            })
          } else {
            this.publicKey = 1
            this.showKeyInfo = false
            this.keyInfo = res.data.public
            this.keyInfoLink = res.data.private
            this.fileName = res.data.file_name
            this.dialogVisible = true
            this.keyInformation = this.prompt.lang_dhop_step_two_success
            this.confirm = this.prompt.lang_dhop_copy_download
          }
        })
          .catch(() => {
            this.publicKey = 2
            this.dialogVisible = true
            this.keyInformation = this.prompt.lang_dhop_step_two_error
            this.confirm = this.prompt.lang_dhop_ok
          })
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    downloadDocument() {
      accessDownloadApi({
        file_url: this.prompt.lang_dhop_about_rsa_key_file
      }).then(res => {
        location.href = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.step_two {
  position: relative;
  .step_line_title {
    display: inline-block;
    width: 226px;
    padding-left: 88px;
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    &:first-child {
      padding-left: 40px;
    }
  }
  .step_line_title_bottom {
    display: inline-block;
    width: 226px;
    padding-left: 10px;
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    &:last-child {
      padding-left: 50px;
    }
  }
  .step_three_title {
    padding-left: 95px;
  }
  .step_three {
    padding-left: 135px;
  }
  .step_disc {
    color: #0e5fac;
  }
  .step_line {
    width: 680px;
    border-top: solid 1px #0e5fac;
    position: relative;
    margin: 15px 0px;
    .circle {
      position: absolute;
      left: -8px;
      top: -10px;
      .step_circle {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: solid 1px #0e5fac;
        background-color: #fff;
        margin-left: 257px;
        &:first-child {
          margin-left: 59px;
        }
      }
      .disc {
        background: #0e5fac;
      }
    }
  }
  .step_two_content {
    display: inline-block;
    vertical-align: top;
    margin-left: 26px;
    padding-top: 8px;
    position: relative;
    .step_step {
      font-size: 18px;
      font-weight: bold;
      color: #0e5fac;
    }
    .step_app_name {
      padding: 24px 0;
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0px;
      color: #666666;
    }
    .step_agreement {
      width: 100%;
      border: solid 1px #dadada;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      > span {
        display: inline-block;
        padding: 10px 20px;
        &:first-child {
          width: 221px;
          padding: 12px 0;
          text-align: center;
          color: #666666;
          border-right: solid 1px #dadada;
          > span {
            color: #ff0000;
            font-weight: bold;
          }
        }
        &:last-child {
          a {
            text-decoration: underline;
            color: #0e5fac;
          }
        }
      }
      > .el-select,
      > .el-input {
        width: calc(100% - 221px);
      }
    }
    .step_bottom {
      margin-bottom: 0;
    }
    .step_agreement_key {
      width: 82%;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      > span {
        display: inline-block;
        padding: 10px 20px;
        &:first-child {
          width: 221px;
          padding: 12px 0;
          text-align: center;
          vertical-align: top;
          color: #666666;
          border: solid 1px #dadada;
          border-right: none;
          > span {
            color: #ff0000;
            font-weight: bold;
          }
        }
        &:last-child {
          a {
            text-decoration: underline;
            color: #0e5fac;
          }
        }
      }
      > .el-textarea {
        width: calc(100% - 221px);
      }
    }
    .step_create_key {
      cursor: pointer;
      padding: 13px 18px;
      border-radius: 5px;
      border: solid 1px #0e5fac;
      background-color: #fff;
      font-size: 18px;
      color: #0e5fac;
      position: absolute;
      top: 297px;
      right: 0;
    }
    .dowmload_document {
      cursor: pointer;
      font-size: 18px;
      color: #0e5fac;
      text-decoration: underline;
      position: absolute;
      top: 355px;
      right: 0;
    }
  }
  .step_button {
    text-align: center;
    margin-top: 70px;
    .step_two_previous {
      cursor: pointer;
      padding: 15px 65px;
      margin-right: 40px;
      border-radius: 5px;
      border: solid 1px #dadada;
      background-color: #fff;
      font-size: 18px;
      color: #666666;
    }
    .step_two_next {
      cursor: pointer;
      padding: 15px 80px;
      background-color: #0e5fac;
      border-radius: 5px;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .error {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
  .error_bottom {
    margin-bottom: 4px;
  }
  .dialog_span {
    color: #666666;
    font-size: 16px;
  }
  .old_key {
    position: absolute;
    top: 310px;
    left: 221px;
    background-color: #fff;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    width: 405px;
    z-index: 1000;
    padding: 10px 0 10px 0;
    li {
      line-height: 30px;
      padding-left: 10px;
      font-size: 16px;
      color: #666666;
      &:hover {
        cursor: pointer;
        background-color: rgba(153, 153, 153, 0.1);
      }
    }
  }
}
</style>
<style lang="less">
.step_two {
  .el-input__inner {
    border: none;
    height: 55px;
    font-size: 16px;
  }
  .el-textarea__inner {
    height: 161px;
    border-radius: 0;
    font-size: 16px;
  }
  .el-dialog__footer {
    text-align: center;
  }
  .el-button--primary {
    background-color: #0e5fac;
    border-color: #0e5fac;
  }
}
</style>
