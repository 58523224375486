<template>
  <div v-loading="loading" class="step_two" element-loading-text="loading...">
    <div class="container">
      <BreadCrumb :type="crumb" />
      <div class="step_two_content">
        <p class="step_app_name" v-html="prompt.lang_step_two_content"></p>
        <div
          class="step_agreement"
          :class="{ step_bottom: showKeyName || sameNameShow }"
        >
          <span>
            {{ prompt.lang_dhop_key_name }}
            <span>*</span>
          </span>
          <el-input v-model="keyName" @input="errorHidden" />
        </div>
        <p v-if="showKeyName" class="error error_bottom">
          {{ prompt.lang_dhop_key_name_cannot_be_empty }}
        </p>
        <p v-if="sameNameShow" class="error error_bottom">
          {{ same_name }}
        </p>
        <div class="step_agreement_key">
          <span class="step_key_info">
            {{ prompt.lang_dhop_key_inof }}
            <span>*</span>
          </span>
          <el-input
            v-model="keyInfo"
            type="textarea"
            :placeholder="
              tArr[0] + '\n' + tArr[1] + '\n' + tArr[2] + '\n' + tArr[3]
            "
            @input="inputHidden"
          />
        </div>
        <p v-if="showKeyInfo" class="error">{{ errorInformation }}</p>
        <button type="button" class="step_create_key" @click="createKey">
          {{ prompt.lang_dhop_create_key_pair }}
        </button>
        <p class="dowmload_document" @click="downloadDocument">
          {{ prompt.lang_dhop_about_rsa_key }}
        </p>
      </div>
      <div class="step_button">
        <button type="button" class="step_two_previous" @click="stepTwoPrevious">
          {{ prompt.lang_cancel }}
        </button>
        <button type="button" class="step_two_next" @click="stepTwoNext">
          {{ prompt.lang_keyinfo_save }}
        </button>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <span class="dialog_span">{{ keyInformation }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button v-if="publicKey == 1" @click="dialogVisible = false">{{
            prompt.lang_cancel
          }}</el-button>
          <el-button type="primary" @click="download">{{ confirm }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { keyInfoKeyApi, licenseCheackRsaApi, licenseKeyApi, licenseRsaApi, accessDownloadApi } from '@/api/partnerProgram'
import BreadCrumb from '@/components/partnerProgram/BreadCrumb'
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      prompt: {
        lang_step_two_content: this.LP.lang_step_two_content,
        lang_dhop_key_name: this.LP.lang_dhop_key_name,
        lang_dhop_key_name_cannot_be_empty: this.LP.lang_dhop_key_name_cannot_be_empty,
        lang_dhop_key_inof: this.LP.lang_dhop_key_inof,
        lang_dhop_create_key_pair: this.LP.lang_dhop_create_key_pair,
        lang_dhop_about_rsa_key: this.LP.lang_dhop_about_rsa_key,
        lang_cancel: this.LP.lang_cancel,
        lang_keyinfo_save: this.LP.lang_keyinfo_save,
        lang_dhop_please_enter_key: this.LP.lang_dhop_please_enter_key,
        lang_dhop_correct_public_key: this.LP.lang_dhop_correct_public_key,
        lang_dhop_key_inof_name_cannot_be_empty: this.LP.lang_dhop_key_inof_name_cannot_be_empty,
        lang_dhop_key_more_five: this.LP.lang_dhop_key_more_five,
        lang_dhop_ok: this.LP.lang_dhop_ok,
        lang_dhop_submitted_successfully: this.LP.lang_dhop_submitted_successfully,
        lang_dhop_step_two_success: this.LP.lang_dhop_step_two_success,
        lang_dhop_copy_download: this.LP.lang_dhop_copy_download,
        lang_dhop_about_rsa_key_file: this.LP.lang_dhop_about_rsa_key_file
      },
      keyName: '',
      keyInfo: '',
      keyInfoLink: '',
      test: 3,
      num: 1,
      tArr: [],
      showKeyName: false,
      showKeyInfo: false,
      errorInformation: '',
      keyInformation: '',
      confirm: '',
      keyId: -1,
      stepName: -1,
      changeInput: -1,
      publicKey: -1,
      dialogVisible: false,
      loading: false,
      sameNameShow: false,
      same_name: ''
    }
  },
  created() {
    this.crumb = 'key_info'
    this.tArr = this.prompt.lang_dhop_please_enter_key.split('\\n')
    if (this.$route.query.status === 'update') {
      keyInfoKeyApi({
        id: this.$route.query.id
      }).then(res => {
        this.loading = false
        this.keyName = res.data.key_name
        this.keyInfo = res.data.public_key
      })
    }
  },
  methods: {
    download() {
      if (this.publicKey === 1) {
        location.href = this.keyInfoLink
      }
      this.dialogVisible = false
    },
    errorHidden() {
      this.showKeyName = false
      this.sameNameShow = false
      this.changeInput = 1
    },
    inputHidden() {
      this.showKeyInfo = false
      this.changeInput = 1
    },
    stepTwoPrevious() {
      this.$router.push({ name: 'KeyInfo' })
    },
    stepTwoNext() {
      if (this.keyName === '') {
        this.showKeyName = true
      }
      if (this.keyInfo === '') {
        this.showKeyInfo = true
        this.errorInformation = this.prompt.lang_dhop_key_inof_name_cannot_be_empty
      } else {
        if (this.$route.query.status === 'update' && this.changeInput === 1) {
          licenseCheackRsaApi({
            public_key: this.keyInfo
          }).then(res => {
            licenseKeyApi({
              type: 'update',
              menu: 'key_info',
              key_name: this.keyName,
              key_id: this.$route.query.id,
              public_key: this.keyInfo,
              select_type: 1
            }).then(res => {
              if (res.message !== 'OK') {
                this.sameNameShow = true
                this.same_name = res.message
              } else {
                this.$alert(this.prompt.lang_dhop_submitted_successfully, {
                  confirmButtonText: this.prompt.lang_dhop_ok,
                  type: 'success',
                  callback: (action) => {
                    this.$router.push({ name: 'KeyInfo' })
                  }
                })
              }
            })
              .catch(() => {
                this.showKeyInfo = true
                this.errorInformation = this.prompt.lang_dhop_correct_public_key
              })
          })
        } else if (this.$route.query.status === 'update') {
          this.$router.push({ name: 'KeyInfo' })
        } else {
          licenseCheackRsaApi({
            public_key: this.keyInfo
          }).then((res) => {
            licenseKeyApi({
              type: 'add',
              menu: 'key_info',
              key_name: this.keyName,
              public_key: this.keyInfo,
              select_type: 1
            }).then(res => {
              if (res.message !== 'OK') {
                this.sameNameShow = true
                this.same_name = res.message
              } else {
                this.$alert(this.prompt.lang_dhop_submitted_successfully, {
                  confirmButtonText: this.prompt.lang_dhop_ok,
                  type: 'success',
                  callback: (action) => {
                    this.$router.push({ name: 'KeyInfo' })
                  }
                })
              }
            })
          })
            .catch(() => {
              this.showKeyInfo = true
              this.errorInformation = this.prompt.lang_dhop_correct_public_key
            })
        }
      }
    },
    createKey() {
      if (this.keyName === '') {
        this.showKeyName = true
      } else {
        licenseRsaApi({
          key_name: this.keyName
        }).then(res => {
          if (res.data === '') {
            this.$alert(this.prompt.lang_dhop_key_more_five, {
              confirmButtonText: this.prompt.lang_dhop_ok,
              type: 'error'
            })
          } else {
            this.publicKey = 1
            this.showKeyInfo = false
            this.keyInfo = res.data.public
            this.keyInfoLink = res.data.private
            this.dialogVisible = true
            this.keyInformation = this.prompt.lang_dhop_step_two_success
            this.confirm = this.prompt.lang_dhop_copy_download
          }
        })
          .catch(() => {
            this.publicKey = 2
            this.dialogVisible = true
            this.keyInformation = this.prompt.lang_dhop_step_two_error
            this.confirm = this.prompt.lang_dhop_ok
          })
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    downloadDocument() {
      accessDownloadApi({
        file_url: this.prompt.lang_dhop_about_rsa_key_file
      }).then(res => {
        location.href = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.step_two {
  padding: 50px 0;
  .step_two_content {
    width: 82%;
    display: inline-block;
    vertical-align: top;
    padding-top: 8px;
    position: relative;
    .step_app_name {
      padding-bottom: 24px;
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0px;
      color: #666666;
    }
    .step_agreement {
      width: 100%;
      border: solid 1px #dadada;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      > span {
        display: inline-block;
        padding: 10px 20px;
        &:first-child {
          width: 221px;
          padding: 12px 0;
          text-align: center;
          color: #666666;
          border-right: solid 1px #dadada;
          > span {
            color: #ff0000;
            font-weight: bold;
          }
        }
        &:last-child {
          a {
            text-decoration: underline;
            color: #0e5fac;
          }
        }
      }
      > .el-input {
        width: calc(100% - 221px);
      }
    }
    .step_bottom {
      margin-bottom: 0;
    }
    .step_agreement_key {
      width: 82%;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      > span {
        display: inline-block;
        padding: 10px 20px;
        &:first-child {
          width: 221px;
          padding: 12px 0;
          text-align: center;
          vertical-align: top;
          color: #666666;
          border: solid 1px #dadada;
          border-right: none;
          > span {
            color: #ff0000;
            font-weight: bold;
          }
        }
        &:last-child {
          a {
            text-decoration: underline;
            color: #0e5fac;
          }
        }
      }
      > .el-textarea {
        width: calc(100% - 221px);
      }
    }
    .step_create_key {
      cursor: pointer;
      padding: 13px 18px;
      border-radius: 5px;
      border: solid 1px #0e5fac;
      background-color: #fff;
      font-size: 18px;
      color: #0e5fac;
      position: absolute;
      top: 197px;
      right: 0;
    }
    .dowmload_document {
      cursor: pointer;
      font-size: 18px;
      color: #0e5fac;
      text-decoration: underline;
      position: absolute;
      top: 260px;
      right: 0;
    }
  }
  .step_button {
    text-align: center;
    margin-top: 70px;
    .step_two_previous {
      cursor: pointer;
      padding: 15px 65px;
      margin-right: 40px;
      border-radius: 5px;
      border: solid 1px #dadada;
      background-color: #fff;
      font-size: 18px;
      color: #666666;
    }
    .step_two_next {
      cursor: pointer;
      padding: 15px 80px;
      background-color: #0e5fac;
      border-radius: 5px;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .error {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
  .error_bottom {
    margin-bottom: 4px;
  }
  .dialog_span {
    color: #666666;
    font-size: 16px;
  }
}
</style>
<style lang="less">
.step_two {
  .el-input__inner {
    border: none;
    height: 55px;
    font-size: 16px;
  }
  .el-textarea__inner {
    height: 161px;
    border-radius: 0;
    font-size: 16px;
  }
  .el-dialog__footer {
    text-align: center;
  }
  .el-button--primary {
    background-color: #0e5fac;
    border-color: #0e5fac;
  }
}
</style>
