<template>
  <div v-loading="loading" class="license-info" element-loading-text="loading...">
    <div class="container">
      <BreadCrumb :type="crumb" />
      <StepOne
        v-if="stepNumber === 1"
        :name="Name"
        :ids="ids"
        @oneNext="stepOne"
      />
      <StepTwo
        v-else-if="
          stepNumber === 2 ||
            (licenseInformation.page_type == 1 && licenseInformation.step == 1)
        "
        :app-ids="appIds"
        :key-names="keyName"
        :keys="public_key"
        @twoNext="stepTwo"
        @twoPrevious="stepTwoPrevious"
      />
      <StepThree
        v-else-if="
          stepNumber === 3 ||
            (licenseInformation.page_type == 1 && licenseInformation.step == 2)
        "
        :app-ids="appIds"
        @threeNext="stepThree"
        @threePrevious="stepThreePrevious"
      />
      <GuideToCreate
        v-else-if="licenseInformation.page_type == 0"
        @childGuide="guideCreate"
      />
      <!-- <LicenseAudit v-if="licenseInformation.page_type === 2" /> -->
      <LicenseList
        v-else-if="licenseInformation.page_type === 3"
        @detailList="createNewApp"
      />
    </div>
  </div>
</template>

<script>
import LicenseList from './LicenseList.vue'
import StepOne from './StepOne'
import StepTwo from './StepTwo.vue'
import StepThree from './StepThree.vue'
import GuideToCreate from './GuideToCreate.vue'
// import LicenseAudit from '../LicenseInfo/licenseAudit'
import BreadCrumb from '@/components/partnerProgram/BreadCrumb'
import { licenseListApi } from '@/api/partnerProgram'
export default {
  components: {
    BreadCrumb,
    LicenseList,
    StepOne,
    StepTwo,
    StepThree,
    GuideToCreate
    // LicenseAudit,
  },
  data() {
    return {
      licenseInformation: {},
      stepInformation: {},
      loading: false,
      stepNumber: -1,
      customShow: -1,
      appIds: -1,
      Name: '',
      keyName: '',
      public_key: '',
      ids: -1
    }
  },
  created() {
    this.crumb = 'license_info'
    this.getLicenseList()
  },
  methods: {
    // 获取license列表接口
    getLicenseList() {
      licenseListApi({
        category: '0'
      }).then(res => {
        this.loading = false
        this.licenseInformation = res.data
      })
    },
    guideCreate(value) {
      this.stepNumber = value
    },
    stepOne(value, id) {
      this.stepNumber = value
      this.appIds = id
    },
    stepTwo(value) {
      this.stepNumber = value
    },
    stepTwoPrevious(value, name, id) {
      this.stepNumber = value
      this.Name = name
      this.ids = id
    },
    stepThree(value) {
      this.stepNumber = value
    },
    stepThreePrevious(value, name, key) {
      this.stepNumber = value
      this.keyName = name
      this.public_key = key
    },
    createNewApp(value) {
      this.stepNumber = value
    }
  }
}
</script>

<style lang="less" scoped>
.license-info {
  padding: 50px 0;
  min-width: 1024px;
}
</style>
