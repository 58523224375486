<template>
  <div v-loading="loading" class="step_one" element-loading-text="loading...">
    <template>
      <span class="step_line_title step_disc">{{ prompt.lang_step_one }}</span>
      <span class="step_line_title">{{ prompt.lang_step_two }}</span>
      <span class="step_line_title step_three">{{
        prompt.lang_step_three
      }}</span>
      <div class="step_line">
        <div class="circle">
          <div class="step_circle disc"></div>
          <div class="step_circle"></div>
          <div class="step_circle"></div>
        </div>
      </div>
      <span class="step_line_title_bottom step_disc">{{
        prompt.lang_create_app_name
      }}</span>
      <span class="step_line_title_bottom">{{
        prompt.lang_step_two_title
      }}</span>
      <span class="step_line_title_bottom step_three_title">{{
        prompt.lang_step_three_title
      }}</span>
    </template>
    <div class="step_one_content">
      <p class="step_app_name">{{ prompt.lang_create_app_name }}</p>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item prop="appName">
          <div class="step_agreement step_one_name">
            <span>
              {{ prompt.lang_dhop_app_name }}
              <span>*</span>
            </span>
            <el-input
              v-model="ruleForm.appName"
              @input="inputHidden"
            />
          </div>
          <p v-if="appNameShow" class="error">
            {{ prompt.lang_dhop_app_name_cannot_be_empty }}
          </p>
          <p v-if="sameNameShow" class="error">
            {{ same_name }}
          </p>
        </el-form-item>
        <p class="name_rules"><span style="color: red;">*</span>{{ prompt.lang_dhop_app_name_prompt }}</p>
      </el-form>
    </div>
    <div class="step_button">
      <button class="step_next" type="button" @click="stepOneNext">
        {{ prompt.lang_dhop_next_step }}
      </button>
    </div>
  </div>
</template>

<script>
import { licenseListApi, licenseAppNameApi } from '@/api/partnerProgram'
export default {
  props: {
    Name: {
      type: String,
      default: ''
    },
    ids: {
      type: Number,
      default: 0
    }
  },
  data() {
    const validateName = (rule, value, callback) => {
      const pattern = new RegExp(/^[A-Za-z0-9_\-\.]+$/)
      const result = pattern.test(this.ruleForm.appName)
      if (value === '') {
        callback(new Error('Please enter your App Name'))
      } else {
        if (result !== true) {
          callback(new Error('Invalid app name'))
        }
        callback()
      }
    }
    return {
      prompt: {
        lang_step_one: this.LP.lang_step_one,
        lang_step_two: this.LP.lang_step_two,
        lang_step_three: this.LP.lang_step_three,
        lang_create_app_name: this.LP.lang_create_app_name,
        lang_step_two_title: this.LP.lang_step_two_title,
        lang_step_three_title: this.LP.lang_dhop_apply_for_license,
        lang_dhop_app_name: this.LP.lang_dhop_app_name,
        lang_dhop_app_name_cannot_be_empty: this.LP.lang_dhop_app_name_cannot_be_empty,
        lang_dhop_app_name_prompt: this.LP.lang_dhop_app_name_prompt,
        lang_dhop_next_step: this.LP.lang_dhop_next_step
      },
      test: 2,
      appNameShow: false,
      stepName: -1,
      changeInput: -1,
      id: -1,
      loading: true,
      same_name: '',
      sameNameShow: false,
      ruleForm: {
        appName: ''
      },
      rules: {
        appName: [{ validator: validateName, trigger: 'blur' }]
      }
    }
  },
  created() {
    this.ruleForm.appName = this.Name
    if (!this.Name && this.ids === -1) {
      licenseListApi({ // 获取license列表接口
        category: '0'
      }).then(res => {
        this.stepName = res.data.page_type
        this.id = +res.data.app_id
        this.loading = false
      })
    } else {
      licenseListApi({ // 获取license列表接口
        category: '0',
        type: '1',
        app_id: this.ids
      }).then(res => {
        this.stepName = res.data.page_type
        this.id = +res.data.app_id
        this.loading = false
      })
    }
  },
  methods: {
    inputHidden() {
      this.appNameShow = false
      this.sameNameShow = false
      this.changeInput = 1
    },
    stepOneNext() {
      if (this.ruleForm.appName === '') {
        this.appNameShow = true
      } else {
        if (
          (this.stepName === 1 && this.changeInput === 1) ||
          (this.stepName === 2 && this.changeInput === 1) ||
          (this.Name && this.ids)
        ) {
          licenseAppNameApi({
            app_name: this.ruleForm.appName,
            type: 'update',
            app_id: this.id
          }).then(res => {
            if (res.message !== 'OK') {
              this.sameNameShow = true
              this.same_name = res.message
            } else {
              this.$emit('oneNext', this.test, res.data.app_id)
            }
          })
        } else if (
          this.stepName === 1 ||
          this.stepName === 2 ||
          (this.Name && this.ids)
        ) {
          licenseAppNameApi({
            app_name: this.ruleForm.appName,
            type: 'update'
          }).then(res => {
            this.$emit('oneNext', this.test, res.data.app_id)
          })
        } else {
          licenseAppNameApi({
            app_name: this.ruleForm.appName,
            type: 'add'
          }).then(res => {
            if (res.message !== 'OK') {
              this.sameNameShow = true
              this.same_name = res.message
            } else {
              this.$emit('oneNext', this.test, res.data.app_id)
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.step_one {
  margin-left: 87.5px;
  .step_line_title {
    display: inline-block;
    width: 226px;
    padding-left: 88px;
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    &:first-child {
      padding-left: 40px;
    }
  }
  .step_line_title_bottom {
    display: inline-block;
    width: 226px;
    padding-left: 10px;
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    &:last-child {
      padding-left: 50px;
    }
  }
  .step_three_title {
    padding-left: 95px;
  }
  .step_three {
    padding-left: 135px;
  }
  .step_disc {
    color: #0e5fac;
  }
  .step_line {
    width: 680px;
    border-top: solid 1px #0e5fac;
    position: relative;
    margin: 15px 0px;
    .circle {
      position: absolute;
      left: -8px;
      top: -10px;
      .step_circle {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: solid 1px #0e5fac;
        background-color: #fff;
        margin-left: 257px;
        &:first-child {
          // margin-top: 14px;
          margin-left: 59px;
        }
      }
      .disc {
        background: #0e5fac;
      }
    }
  }
  .step_one_content {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding-top: 8px;
    .step_step {
      font-size: 18px;
      font-weight: bold;
      color: #0e5fac;
    }
    .step_app_name {
      padding: 24px 0;
      font-size: 16px;
      color: #666666;
    }
    .step_agreement {
      width: 100%;
      border: solid 1px #dadada;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      margin-bottom: 20px;
      > span {
        display: inline-block;
        padding: 10px 20px;
        &:first-child {
          width: 221px;
          padding: 12px 0;
          text-align: center;
          color: #666666;
          border-right: solid 1px #dadada;
          > span {
            color: #ff0000;
            font-weight: bold;
          }
        }
        &:last-child {
          a {
            text-decoration: underline;
            color: #0e5fac;
          }
        }
      }
      > .el-input {
        width: calc(100% - 221px);
      }
    }
    .step_one_name {
      margin-bottom: 0;
    }
    .error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
    }
  }
  .step_button {
    text-align: center;
    margin-top: 73px;
    .step_next {
      cursor: pointer;
      padding: 14px 66px;
      background-color: #0e5fac;
      border-radius: 5px;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .name_rules {
    max-width: 1105px;
    line-height: 20px;
    font-size: 14px;
  }
}
</style>
<style lang="less">
.step_one {
  .el-input__inner {
    border: none;
    height: 55px;
    font-size: 16px;
  }
}
</style>
