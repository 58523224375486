<template>
  <div class="eco-partner">
    <public-banner :banner-info="bannerInfo" :banner-content="bannerContent" />
    <!-- Why Choose ? -->
    <div class="eco-partner-choose container">
      <p class="eco-partner-choose-title">{{ LP.lang_why_choose	}}</p>
      <div class="eco-partner-choose-content">
        <div
          v-for="(reason, reasonIndex) in reasonList"
          :key="reasonIndex"
          class="eco-partner-choose-reason"
        >
          <reasons-for-selection :title="reason.title" :content="reason.content" :avatar="reason.image" />
        </div>
      </div>
    </div>
    <!-- partner level -->
    <div class="partner-level container">
      <h2>Partner levels</h2>
      <h3>Fields of cooperation (*)</h3>
      <table border="1" cellpadding="10" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th></th>
            <th>Technology</th>
            <th>Marketing</th>
            <th>Project & Sales</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th width="36%">
              <p class="blue-text">Registered ECO Partner</p>
              <p>For partners that wish to connect with the Dahua partner community</p>
            </th>
            <th>Basic</th>
            <th>Basic</th>
            <th>-</th>
          </tr>
          <tr style="background: #efefef;">
            <th>
              <p class="blue-text">ECO+ Partner </p>
              <p>For partners with proven success in delivering joint solutions</p>
            </th>
            <th>Advanced</th>
            <th>Advanced</th>
            <th>Advanced</th>
          </tr>
          <tr>
            <th>
              <p class="blue-text">ECO Value Partner</p>
              <p>For strategical partners with a mutually-rewarding commercial cooperation</p>
            </th>
            <th>Premium</th>
            <th>Premium</th>
            <th>Premium</th>
          </tr>
        </tbody>
      </table>
      <h4>(*) Ask for the Benefits and Requirements matrix and get detailed info </h4>
    </div>
    <!-- <div v-if="loginParams == '1'" class="partner-img container" :class="imgHeight ? 'hidden' : 'show'">
      <img style="width: 100%;" :src="LP.lang_partner_levels_image" alt="" @load="getImageHeight" />
    </div> -->
    <div v-if="loginParams == '1'" class="partner-img container">
      <img class="img-box" :src="LP.lang_partner_levels_image" alt="" />
    </div>
    <!-- <p v-if="viewAllShow" class="view-all" @click="viewAll">{{ imgHeight ? LP.lang_view_all : LP.lang_pack_up }}</p> -->

    <!-- Benifits to Requirements -->
    <!-- <div class="eco-partner-tabs container">
      <public-tabs-round :data="tabs" @tabsClick="tabsClick" />
      <div class="eco-partner-tabs-content">
        <div v-if="index == 0">
          <table cellpadding="0" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th width="170" class="mobileNone"></th>
                <th></th>
                <th width="170">{{ LP.lang_eco_partner }}</th>
                <th width="170">{{ LP.lang_eco_plus_partner	}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="mobileNone">
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="center">
                        <span class="iconfont icon-a-icon-permission11x table-left-icon"></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_accessibility }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_partner_portal_access }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">{{ LP.lang_open }}</td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">{{ LP.lang_after_review }}</td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td class="mobileNone">
                  <table width="100%">
                    <tr>
                      <td rowspan="3" align="center">
                        <span class="iconfont icon-a-icon-training261x table-left-icon"></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_dedicated_technical_support }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_certification }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_roadmap_sharing }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center">
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td class="mobileNone">
                  <table width="100%">
                    <tr>
                      <td rowspan="7" align="center">
                        <span class="iconfont icon-a-icon-cpmmunication371x table-left-icon"></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_eco_partner_content }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_regular_communication	}}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_marketing_strategy_sharing	}}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_partner_options }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_communication_stories }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_solutions_communication	}}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_dedicated_training }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center">{{ LP.lang_online }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">{{ LP.lang_face_to_face }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td class="mobileNone">
                  <table width="100%">
                    <tr>
                      <td rowspan="4" align="center">
                        <span class="iconfont icon-a-icon-share481x table-left-icon"></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_project_support }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_active_lead_generation }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_sales_strategy	}}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_dedicated_cooperation }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table cellpadding="0" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th width="170" class="mobileNone"></th>
                <th></th>
                <th width="200">{{ LP.lang_registered_eco_partner }}</th>
                <th width="170">{{ LP.lang_eco_plus_partner	}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="mobileNone">
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="center">
                        <span class="iconfont icon-a-icon-sign51x table-left-icon"></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_signed_agreement }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_regular_meetings }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td class="mobileNone">
                  <table width="100%">
                    <tr>
                      <td rowspan="2" align="center">
                        <span class="iconfont icon-a-icon-training261x table-left-icon"></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>Dedicated technical support</td>
                    </tr>
                    <tr>
                      <td>Roadmap sharing</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td class="mobileNone">
                  <table width="100%">
                    <tr>
                      <td rowspan="3" align="center">
                        <span class="iconfont icon-a-icon-cpmmunication371x table-left-icon"></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_dahua_content_on_website }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_marketing_strategy_sharing	}}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_regular_partnering_activities	}}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td class="mobileNone">
                  <table width="100%">
                    <tr>
                      <td rowspan="3" align="center">
                        <span class="iconfont icon-a-icon-share481x table-left-icon"></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td>{{ LP.lang_project_support }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_sales_strategy }}</td>
                    </tr>
                    <tr>
                      <td>{{ LP.lang_dedicated_cooperation }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td align="center"></td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table width="100%">
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-a-gou1x" />
                        </svg>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
    <!-- Ready to Beacome -->
    <div class="eco-partner-become">
      <p class="common-title">{{ LP.lang_ready_to_beacome }}</p>
      <button class="common-button join-button" @click="handleIoinNow('0')">{{ LP.lang_dipp_join_us_now	}}</button>
    </div>
    <!-- Intersted in ECO Partners -->
    <div class="eco-partner-intersted">
      <p class="common-title find-title">{{ LP.lang_do_you_intersted }}</p>
      <button class="common-button find-button" @click="handleIoinNow('1')">{{ LP.lang_find_a_eco_partner }}</button>
    </div>
  </div>
</template>

<script>
import ReasonsForSelection from '@/components/partnerProgram/ReasonsForSelection'
import PublicTabsRound from '@/components/public/PublicTabsRound'
import PublicBanner from '@/components/public/PublicBanner.vue'
import { mapActions } from 'vuex'
import { getCookie } from '@/utils/cookie'
export default {
  components: {
    ReasonsForSelection,
    PublicTabsRound,
    PublicBanner
  },
  data() {
    return {
      // banner模块内容
      bannerInfo: {
        title: '',
        img: ''
      },
      bannerContent: {
        title: '',
        desc: '',
        link: '',
        linkText: ''
      },
      reasonList: [
        {
          image: require('@/assets/img/partnerProgram/icon-power.png'),
          title: this.LP.lang_eco_empower,
          content: this.LP.lang_eco_description_empower
        },
        {
          image: require('@/assets/img/partnerProgram/icon-cooperate.png'),
          title: this.LP.lang_eco_cooperate,
          content: this.LP.lang_eco_description_cooperate
        },
        {
          image: require('@/assets/img/partnerProgram/icon-open.png'),
          title: this.LP.lang_open,
          content: this.LP.lang_eco_description_open
        }
      ],
      tabs: [
        { name: this.LP.lang_benifits },
        { name: this.LP.lang_requirements }
      ],
      index: 0,
      imgHeight: '',
      viewAllShow: ''
    }
  },
  computed: {
    loginParams() {
      return getCookie('isShow')
    }
  },
  created() {
    this.getBanner()
  },
  methods: {
    ...mapActions('common', ['getBannerImg']),
    // 获取轮播图
    async getBanner() {
      const data = await this.getBannerImg({ num: 142 })
      this.bannerInfo.img = data.data.img_image
      this.bannerContent.title = data.data.program_name
      this.bannerContent.desc = data.data.program_description
    },
    tabsClick(item, index) {
      this.index = index
    },
    // Join Us Now
    handleIoinNow(i) {
      if (i === '0') {
        this.$router.push('/register/Eco')
      } else {
        this.$router.push('/partners/list?type=1')
      }
    }
    // getImageHeight(event) {
    //   this.imgHeight = event.target.naturalHeight > 600
    //   this.viewAllShow = event.target.naturalHeight > 600
    // },
    // viewAll() {
    //   this.imgHeight = !this.imgHeight
    // }
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/pages/partnerProgram/common";
@import '~@/styles/common/common';
.eco-partner {
  /deep/ .public-banner {
     .banner-content {
       .content-desc {
         text-align: left
       }
     }
  }
  .common-button {
    .transition();
    &:hover {
      opacity: 0.7;
    }
  }
	.join-button{
    background-color: @theme;
    color: #ffffff;
  }
	.find-title{
		color: #ffffff;
	}
	.find-button{
		background-color: #ffffff;
		color: @theme;
	}

}
.partner-img{
  margin-top: 72px;
  text-align: center;
  .img-box {
    max-width: 100%;
  }
}
.hidden{
  overflow: hidden;
  height: 600px;
}
.show{
  overflow: visible;
  height: auto;
}
// .view-all{
//   text-align: center;
//   margin-top: 40px;
//   font-weight: bold;
//   font-size: 20px;
//   cursor: pointer;
// }
// .view-all:hover{
//   color: #319BD8;
// }
.partner-level{
  margin-top: 72px;
  h2{
    font-weight: 400;
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
  h3{
    color: #333333;
    font-size: 16px;
    text-align: right;
    font-weight: normal;
    margin-bottom: 20px;
  }
  h4{
    color: #333333;
    font-size: 16px;
    text-align: left;
    font-weight: normal;
    margin-top: 20px;
  }
  table{
    border-color: #CCC;
    thead{
      background: #319BD8;
      color: #FFF;
      text-align: center;
      tr{
        height: 36px;
        th{
          font-weight: normal;
          font-size: 16px;
          @media screen and (max-width: 768px) {
            padding: 2px;
            font-size: 14px;
          }
        }
      }
    }
    tbody{
      tr{
        th{
          font-size: 16px;
          @media screen and (max-width: 768px) {
            padding: 2px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.blue-text{
  color: #319BD8;
  margin-bottom: 10px;
}
</style>
