<template>
  <div class="dhop-wrap container">
    <div class="contact">
      <div class="contact-content">
        <!-- free trial 私有 -->
        <div v-if="ruleForm.type === 2">
          <p class="title">{{ LP.lang_free_trial_form }}</p>
          <div class="free">
            <span>{{ LP.lang_license_quantity }}</span>
            <span>{{ LP.lang_charge_free }}</span>
            <span>{{ LP.lang_trial_time	}}</span>
          </div>
        </div>

        <p v-else class="title">{{ LP.lang_contact_form }}</p>
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px">
          <p class="small-title">{{ LP.lang_basic_information }}</p>
          <div class="input">
            <div class="label">
              <span class="xing">*</span>
              <span class="label-title">{{ LP.lang_first_name	}}</span>
            </div>
            <el-form-item class="form-item" prop="firstName">
              <el-input v-model="ruleForm.firstName" />
            </el-form-item>
          </div>
          <div class="input">
            <div class="label">
              <span class="xing">*</span>
              <span class="label-title">{{ LP.lang_last_name }}</span>
            </div>
            <el-form-item class="form-item" prop="lastName">
              <el-input v-model="ruleForm.lastName" />
            </el-form-item>
          </div>
          <div class="input">
            <div class="label">
              <span class="xing">*</span>
              <span class="label-title">{{ LP.lang_company }}</span>
            </div>
            <el-form-item class="form-item" prop="companyName">
              <el-input v-model="ruleForm.companyName" />
            </el-form-item>
          </div>
          <div class="input">
            <div class="label">
              <span class="xing">*</span>
              <span class="label-title">{{ LP.lang_position }}</span>
            </div>
            <el-form-item class="form-item" prop="position">
              <el-input v-model="ruleForm.position" />
            </el-form-item>
          </div>
          <div class="input">
            <div class="label">
              <span class="xing">*</span>
              <span class="label-title">{{ LP.lang_email }}</span>
            </div>
            <el-form-item class="form-item" prop="email">
              <el-input v-model="ruleForm.email" />
            </el-form-item>
          </div>
          <div class="input">
            <div class="label">
              <span class="xing">*</span>
              <span class="label-title">{{ LP.lang_phone }}</span>
            </div>
            <el-form-item class="form-item" prop="phone">
              <el-input v-model="ruleForm.phone" />
            </el-form-item>
          </div>
          <div class="input">
            <div class="label">
              <span class="xing">*</span>
              <span class="label-title">{{ LP.lang_country_region }}</span>
            </div>
            <el-select v-model="ruleForm.country" class="form-item" prop="country" placeholder>
              <el-option
                v-for="item in countryList"
                :key="item.region_id"
                :label="item.region_name"
                :value="item.region_id"
              />
            </el-select>
          </div>
          <p class="small-title information">{{ LP.lang_project_information }}</p>
          <div class="input text-input">
            <div class="label">
              <span class="xing">*</span>
              <span class="label-title">{{ LP.lang_project_description }}</span>
            </div>
            <el-form-item class="area-item" prop="projrct_description">
              <el-input v-model="ruleForm.projrct_description" type="textarea" />
            </el-form-item>
          </div>
          <template>
            <el-checkbox v-model="agree">
              <span class="xing xinxing">*</span>
              <span class="privacy" v-html="privacy"></span>
            </el-checkbox>
          </template>
          <div class="submit">
            <el-button type="primary" @click.native="handleSubmit('ruleForm')">{{ LP.lang_submit }}</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { countrySelectApi } from '@/api/solutions'
import { partnerContactFormApi } from '@/api/partners'
export default {
  name: 'ContactForm',
  data() {
    return {
      ruleForm: {
        application_id: this.$route.query.id,
        firstName: '',
        lastName: '',
        companyName: '',
        position: '',
        email: '',
        phone: '',
        country: '',
        project_description: '',
        type: this.$route.query.type
      },
      agree: 0,
      rules: {
        firstName: [
          {
            required: true,
            trigger: 'blur'
          }
        ],
        lastName: [
          {
            required: true,
            trigger: 'blur'
          }
        ],
        companyName: [
          {
            required: true,
            trigger: 'blur'
          }
        ],
        position: [
          {
            required: true,
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            trigger: 'blur'
          },

          { type: 'email', trigger: 'blur' }
        ],
        phone: [
          {
            required: true,
            trigger: 'blur'
          }
        ],
        country: [
          {
            required: true,
            trigger: 'blur'
          }
        ],
        project_description: [
          {
            required: true,
            trigger: 'blur'
          }
        ]
      },
      countryList: [],
      privacy: this.LP.lang_app_contact_agree
    }
  },
  created() {
    this.getCountry()
  },
  methods: {
    getCountry() {
      countrySelectApi().then(res => {
        this.countryList = res.data
      })
    },
    // 提交校验
    handleSubmit(formName) {
      if (this.agree === 0) {
        this.$alert('Please check the privacy agreement first', {
          confirmButtonText: 'confirm',
          type: 'warning'
        })
        return
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            partnerContactFormApi({
              ...formName,
              agree: 1
            }).then(res => {
              if (res.message === 'Email registered') {
                this.$alert('This email has submitted an application', {
                  confirmButtonText: 'confirm',
                  type: 'error'
                })
              } else {
                this.$alert(
                  'Submitted successfully. Thank you for your submission',
                  {
                    confirmButtonText: 'confirm',
                    type: 'success',
                    callback: action => {
                      this.$refs[formName].resetFields()
                      this.ruleForm.country = ''
                      this.agree = 0
                    }
                  }
                )
              }
            })
          } else {
            return false
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dhop-wrap {
  min-height: 900px;
  .contact {
    min-height: 700px;
    background-color: #fff;
    margin-bottom: 146px;
    padding-bottom: 150px;
    .contact-content {
      max-width: 1206px;
      margin: 0 auto;
      padding-top: 29px;
      .free {
        margin-bottom: 40px;
        span {
          padding-right: 20px;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 24px;
          letter-spacing: 0px;
          color: #333333;
        }
      }
      .title {
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #0e5fac;
        margin: 40px 0 32px;
      }
      .small-title {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #333333;
        padding-bottom: 32px;
      }
      .information {
        margin-top: 27px;
      }
      .input {
        display: inline-block;
        width: 47%;
        .label {
          margin-bottom: 10px;
          .label-title {
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #333333;
            padding-left: 5px;
          }
        }
        .form-item {
          width: 96%;
        }
      }
      .text-input {
        width: 100%;
      }
      .xing {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #ff0000;
      }
      .xinxing {
        padding-right: 5px;
      }
      .privacy {
        width: 92%;
        display: inline-block;
        white-space: normal;
        vertical-align: top;
      }
      .submit {
        margin-top: 40px;
        button {
          width: 120px;
          background-color: #0e5fac;
        }
      }
      .point {
        cursor: pointer;
        &:hover {
          color: #007aff;
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-form-item__content {
  margin-left: 0 !important;
}
.el-textarea__inner {
  min-height: 123px !important;
}
.el-breadcrumb__inner a {
  color: #606266;
  font-weight: normal;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
.el-breadcrumb__inner.is-link {
  color: #606266;
  font-weight: normal;
}
</style>
