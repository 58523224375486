import { render, staticRenderFns } from "./LicenseList.vue?vue&type=template&id=47fc3914&scoped=true&"
import script from "./LicenseList.vue?vue&type=script&lang=js&"
export * from "./LicenseList.vue?vue&type=script&lang=js&"
import style0 from "./LicenseList.vue?vue&type=style&index=0&id=47fc3914&lang=less&scoped=true&"
import style1 from "./LicenseList.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47fc3914",
  null
  
)

export default component.exports