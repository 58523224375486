<template>
  <div>
    <div v-if="loading" v-loading="loading" element-loading-text="loading..."></div>
    <div v-else class="sdk_file container">
      <template v-if="fileList && fileList.length > 0">
        <div v-for="(item, index) in fileList" :key="index">
          <div class="file_name" @click="showInformation(item, index)">
            <i
              :class="
                item.isShow ? 'el-icon-caret-right' : 'el-icon-caret-bottom'
              "
            ></i>
            <span>
              {{ LP.lang_product_line }}:
              <span>{{ item.line_name }}</span>
            </span>
            <span>
              {{ LP.lang_product_series }}:
              <span>{{ item.series_name }}</span>
            </span>
          </div>
          <div
            v-for="(detail, detailIndex) in item.type_data"
            v-show="!item.isShow"
            :key="detailIndex"
            class="product_list"
          >
            <span
              v-if="detail.menu_value && detail.menu_value.length > 0"
              class="product_title"
            >
              <span>{{ detail.menu_name }}</span>
            </span>
            <div class="product_file">
              <p
                v-for="(download, downloadIndex) in detail.menu_value"
                :key="downloadIndex"
              >
                <span class="product_name">
                  <span>{{ download.title }}</span>
                </span>
                <span class="product_link">
                  <span @click="downloadFile(download)">{{
                    download.file_name
                  }}</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="no-data">
          <img src="@/assets/img/partnerProgram/sdk_no_data.png" alt="" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { sdkList } from '@/api/partnerProgram'
export default {
  data() {
    return {
      loading: true,
      fileList: []
    }
  },
  computed: {
    ...mapState({
      isToken: (state) => state.login.token
    })
  },
  mounted() {
    sdkList().then((res) => {
      this.fileList = res.data
      this.loading = false
    })
  },
  methods: {
    showInformation(item, i) {
      if (!item.isShow) {
        this.$set(item, 'isShow', false)
        item.isShow = !item.isShow
      } else {
        item.isShow = !item.isShow
      }
    },
    downloadFile(data) {
      location.href = `${document.location.origin}/depp/dhop_manager/download_new?type=${data.type}&&line_id=${data.line_id}&&series_id=${data.series_id}&&secret=${data.secret}&&title=${data.title}&&file_name=${data.file_name}&&token=${this.isToken}&&download_type=SDK`
    }
  }
}
</script>

<style lang="less" scoped>
.sdk_file {
  padding: 50px 0px 30px;
  .file_name {
    cursor: pointer;
    margin-bottom: 20px;
    padding: 10px 0 10px 10px;
    border: solid 1px #0e5fac;
    > span {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 29px;
      letter-spacing: 0px;
      color: #333333;
      padding-left: 25px;
      display: inline-block;
      min-width: 200px;
      span {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #0e5fac;
      }
    }
  }
  .product_list {
    // padding-left: 10px;
    height: 100%;
    margin-bottom: 20px;
    display: flex;
    .product_title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      background-color: #5b9cda;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      vertical-align: top;
      span {
        display: inline-block;
      }
    }
    .product_file {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 160px);
      height: 100%;
      p {
        width: 100%;
        display: flex;
        min-height: 44px;
        min-width: 600px;
        border: solid 1px #dadada;
        border-left: none;
        border-bottom: none;
        overflow: hidden;
        &:last-child {
          border-bottom: solid 1px #dadada;
        }
        .product_name {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 250px;
          min-height: 44px;
          min-width: 250px;
          height: 100%;
          border-right: solid 1px #dadada;
          text-align: center;
          span {
            display: inline-block;
            word-break: break-all;
            // max-width: 150px;
            font-size: 16px;
          }
        }
        .product_link {
          display: flex;
          align-items: center;
          min-height: 44px;
          min-width: 350px;
          height: 100%;
          span {
            display: inline-block;
            width: 100%;
            font-size: 16px;
            text-decoration: underline;
            color: #0e5fac;
            padding-left: 19px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .no-data {
    text-align: center;
    img {
      max-width: 1026px;
      width: 100%;
    }
  }
}
</style>
