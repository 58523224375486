<template>
  <div class="sub-page">
    <div class="detail container">
      <div class="img-box pc-img">
        <img :src="detailData.image" alt />
      </div>
      <div class="detail-title">
        <span class="right-title">{{ detailData.application_name }}</span>
        <span class="left-title">{{ LP.lang_by }} <a :href="detailData.link">{{ detailData.company_name }}</a></span>
        <!-- 移动端显示图片 -->
        <div class="img-box mobile-img">
          <img :src="detailData.image" alt />
        </div>
        <p class="available">{{ detailData.available_in }}: <span class="detail-info">{{ detailData.region }}</span> </p>
        <p class="current">{{ detailData.current_version }}: <span class="detail-info">{{ detailData.version }}</span></p>
      </div>
    </div>

    <!-- <div class="clear"></div> -->

    <div class="content container" v-html="detailData.description"></div>

    <div class="preview container">
      <public-title :title="detailData.preview" />
      <swiper ref="swiper-banner" class="swiper index-swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in detailData.rotation" :key="index" class="preview-slide">
          <video
            v-if="item.type == 1"
            :src="item.link"
            controls="controls"
            x5-video-player-type="h5"
            class="header_right swiper-item"
            @click="handleVideo(item.link)"
          ></video>
          <img v-else class="banner-img swiper-lazy swiper-item" :src="item.image" alt @click="imgHandler(item.image)" />
        </swiper-slide>
        <div slot="button-prev" class="swiper-button-prev swiper-button">
          <span class="el-icon-arrow-left"></span>
        </div>
        <div slot="button-next" class="swiper-button-next swiper-button">
          <span class="el-icon-arrow-right"></span>
        </div>
        <div class="swiper-pagination swiper-pagination-bullets"></div>
      </swiper>
    </div>
    <div class="models container">
      <public-title :title="detailData.available_models" />
      <div class="folding">
        <el-collapse>
          <el-collapse-item class="collapse-item">
            <template slot="title">
              <div class="line-title">
                {{ detailData.click_here }}
              </div>
            </template>
            <div v-for="(item, index) in detailData.model" :key="index">
              <div class="sub-title">{{ item.menu_name }}</div>
              <div class="contant-detail">
                <div v-for="e in item.product" :key="e.product_id" class="detail-link">
                  <span>
                    <a :href="e.product_detail">{{ e.product_name }},</a>
                  </span>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="models-btn">
        <el-button type="info" class="btn-self" @click="contactHandler()">{{ detailData.contact }}</el-button>
        <el-button type="info" class="btn-self" @click="trialHandler()">{{ detailData.free_trial }}</el-button>
      </div>
    </div>

    <public-dialog :visible="visibleVideo" :show-cancel="false" :show-submit="false" :element-config="elementUsed" @click.native="cancel">
      <video
        :src="videoLink"
        controls="controls"
        x5-video-player-type="h5"
        class="header_right"
      ></video>
    </public-dialog>
    <public-dialog :visible="visibleImg" :show-cancel="false" :show-submit="false" :element-config="elementUsed" @click.native="cancel">
      <img :src="imgsrc" alt class="header_right" />
    </public-dialog>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import PublicTitle from '@/components/public/PublicTitle.vue'
import publicDialog from '@/components/public/PublicDialog'
import { subPageDetailApi, partnerHitsApi } from '@/api/partners'
export default {
  components: {
    swiper,
    swiperSlide,
    PublicTitle,
    publicDialog
  },
  data() {
    return {
      detailData: [], // 数据详情
      visibleImg: false, // 图片对话框开关
      visibleVideo: false, // 视频对话框开关
      elementUsed: {
        title: ''
      },
      imgsrc: '',
      videoLink: '',
      swiperOption: {
        slidesPerView: 4, // 轮播图每屏幕显示个数
        spaceBetween: 16,
        navigation: { // 前进后退按钮
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1320: {
            slidesPerView: 3
          },
          1024: {
            slidesPerView: 2.2
          },
          768: {
            slidesPerView: 1.1
          }
        }
      }
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    // 获取数据详情
    getDetail() {
      subPageDetailApi({
        id: this.$route.query.id
      }).then(res => {
        this.detailData = res.data
      }).catch(e => e)
      partnerHitsApi({
        id: this.$route.params.id
      }).catch(e => e)
    },
    // 图片对话框图片
    imgHandler(img) {
      this.visibleImg = true
      this.imgsrc = img
    },
    // 视频对话框
    handleVideo(link) {
      this.visibleVideo = true
      this.videoLink = link
    },
    // 关闭对话框
    cancel() {
      this.visibleImg = false
      this.visibleVideo = false
    },
    // contact 按钮
    contactHandler() {
      this.$router.push({
        name: 'cantactForm',
        query: {
          type: 1,
          id: this.detailData.id
        }
      })
    },
    // free trial 按钮
    trialHandler() {
      this.$router.push({
        name: 'cantactForm',
        query: {
          type: 2,
          id: this.detailData.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.sub-page {
	overflow: hidden;
  .detail {
    display: flex;
    padding: 48px 0 24px;
    .img-box {
      margin: 0 16px 24px 0;
      width: 308px;
      height: 206px;
      box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
      border-radius: 4px;
			display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .detail-title {
        font-size: 18px;
        line-height: 24px;
        color: #303133;
        font-weight: 600;
      .right-title {
        margin-right: 24px;
        width: 262px;
        height: 43px;
        font-size: 32px;
        line-height: 43px;
      }
			.mobile-img{
				display: none;
			}
      .available {
        margin: 85px 0 16px;
      }
      .detail-info {
        color: #0E5FAC
      }
    }
  }
  .content {
		display: block;
    font-size: 16px;
    color: #606266;
    line-height: 21px;
    padding: 24px 0px;
    border-bottom: 1px solid #ECEEF2;
    border-top: 1px solid #ECEEF2;
  }
  .preview {
    text-align: center;
    margin: 72px auto;
    .swiper {
      .preview-slide .swiper-item {
        height: 206px;
				width: 308px !important;
        cursor: pointer;
				border-radius: 4px;
				box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
       .banner-img {
					height: 206px;
        }
      }
      .swiper-button {
        position: absolute;
        width: 50px;
        height: 64px;
        line-height: 64px;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        font-size: 24px;
        background-color: rgba(0, 0, 0, 0.2);
        background-image: none;
        color: #fff;
        border: none;
        z-index: 1;
        transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);

        &:hover {
          background-color: #0e5fac;
        }
      }
      .swiper-button-prev {
        left: 0;
      }
      .swiper-button-next {
        right: 0;
      }
    }
  }
	.swiper-container{
    overflow: visible;
  }
	.swiper-button-next, .swiper-button-prev{
    display: none;
  }
  .header_right {
    width: 100%;
    height: 100%;
  }
  .models {
    text-align: center;
   .folding {
      .collapse-item {
        /deep/ .el-collapse-item__header {
            font-size: 18px;
            border: 1px dashed #979797;
        }
         /deep/ .is-active {
            border-bottom: 1px dashed transparent;
        }
        /deep/ .el-collapse-item__content {
          text-align: left;
          border: 1px dashed #979797;
          border-top:1px dashed transparent;
            .sub-title {
              border-bottom: 1px dashed #dadada;
              color: #666;
              font-weight: 600;
              padding: 5px 30px;
            }
            .contant-detail {
              padding-left: 32px;
              .detail-link {
                display: inline-block;
                color: #666;
                margin: 10px 0 20px;
                cursor: pointer;
                span {
                  padding-left: 5px;
                }
              }
            }
        }
        .line-title {
         padding: 0 28px;
        }
      }
    }
    .models-btn {
      margin: 72px 0;
			.btn-self{
				background-color: @theme;
			}
    }
  }
	@media screen and (max-width:768px) {
		.detail{
			padding: 24px 0;
			.pc-img{
				display: none;
			}
			.detail-title{
				width: 100%;
				text-align: center;
				.right-title{
					font-size: 24px;
					line-height: 32px;
					margin-right: 0;
				}
				.left-title{
					display: block;
					padding: 10px 0;
					font-size: 16px;
					line-height: 21px;
				}
				.mobile-img{
					display: block;
					margin: auto;
				}
				.available{
					margin: 24px 0 16px;
				}
			}
		}
		.content{
			font-size: 14px;
			line-height: 19px;
			padding: 16px 0;
		}
		.preview{
			margin: 24px auto;
			/deep/.public-title{
				.title{
					font-size: 24px;
					line-height: 32px;
					margin-bottom: 16px;
				}
			}
		}
		.models{
			/deep/.public-title{
				.title{
					font-size: 24px;
					line-height: 32px;
					margin-bottom: 16px;
				}
			}
			.folding{
				/deep/.el-collapse-item__arrow{
					display: none;
				}
				.collapse-item {
					.line-title{
						padding: 0;
					}
					/deep/.el-collapse-item__header{
						font-size: 16px;
						line-height: 21px;
						height: auto;
						padding: 15px 16px;
					}
					.el-collapse-item__content{
						padding-bottom: 16px;
						.contant-detail{
							.detail-link{
								margin-bottom: 5px;
							}
						}
					}
				}
			}
			.models-btn{
				margin: 24px auto 48px;
				/deep/.el-button{
					font-size: 16px;
					line-height: 21px;
					padding: 14px 20px;
					background-color: @theme;
					border:1px solid @theme;
				}
			}
		}
	}
}
</style>
<style lang="less">
.index-swiper {
  .swiper-pagination-bullet {
    position: relative;
    width: 10px;
    height: 10px;
    background-color: #fff;
    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: #ccc;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      // transform: translate(-50%, -50%);
    }
    &:hover {
      border: solid 1px #0e5fac;
      &:before {
        background-color: #0e5fac;
      }
    }
  }
  .swiper-pagination-bullet-active {
    border: solid 1px #0e5fac;
    &:before {
      background-color: #0e5fac;
    }
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    font-size: medium;
  }
}
.public-dialog-container{
	.el-dialog__body{
		.header_right{
			width: 100%;
		}
	}
}
</style>
