<template>
  <div class="reasons">
    <div class="zanding">
      <img :src="avatar" alt="" class="icon-logo" />
    </div>
    <p class="reasons-title">{{ title }}</p>
    <p class="reasons-content">{{ content }}</p>
  </div>
</template>

<script>
export default {
  props: {
    // 暂定，根据接口返回值确定是图片还是其他
    avatar: {
      type: String,
      default: ''
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 内容
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.reasons {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 18px 0px rgba(38, 38, 38, 0.14);
  position: relative;
  .zanding {
    width: 72px;
    height: 72px;
		line-height: 72px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    text-align: center;
    line-height: 72px;
    transform: translate(-50%, -50%);
		text-align: center;
    .shadow()
  }
  .reasons-title {
    font-size: 18px;
    font-weight: bold;
    color: #303133;
    line-height: 24px;
    padding: 52px 16px 0px;
    text-align: center;
  }
  .reasons-content {
    font-size: 16px;
    color: #606266;
    line-height: 1.5;
    padding: 10px 16px 26px;
    text-align: center;
  }
}
</style>
