<template>
  <div class="bread">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(item,index) in breadList"
        :key="index"
        :to="{name: 'DHOPManager'}"
      >{{ item.name }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { dhopNavApi } from '@/api/partnerProgram'
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      breadList: []
    }
  },
  created() {
    this.getDhopNavApi()
  },
  methods: {
    // 获取面包屑接口
    getDhopNavApi() {
      dhopNavApi({
        type: this.type
      }).then(res => {
        this.breadList = res.data
      })
    }
  }
}
</script>

<style lang="less">
.bread {
  .is-link {
    font-size: 16px;
    color: #0e5fac;
  }
  .el-breadcrumb {
    margin-bottom: 40px;
  }
}
</style>
