<template>
  <div class="public-tabs-round">
    <div class="tabs-content">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="tabs-item"
        :class="{
          'active': currentIndex === index,
          'is-black': currentIndex === index && isBlackText
        }"
        @click="handleClick(item, index)"
      >
        <p class="tabs-name">{{ item[prop.label] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicTabsRound',
  props: {
    // 名称
    data: {
      type: Array,
      default: () => []
    },
    // 是否是黑色字体
    isBlackText: {
      type: Boolean,
      default: false
    },
    // 显示标签字段名，唯一字段名
    prop: {
      type: Object,
      default: () => ({
        id: 'id',
        label: 'name'
      })
    }
  },
  data() {
    return {
      currentIndex: 0 // 当前选中索引值
    }
  },
  methods: {
    // 点击切换菜单
    handleClick(item, index) {
      this.currentIndex = index
      this.$emit('tabsClick', item, index)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.public-tabs-round {
  margin-bottom: 24px;
  .tabs-content {
    display: inline-flex;
    border-radius: 44px;
    padding: 12px 18px;
    border: 1px solid #D8D8D8;
    .tabs-item {
      padding: 0 30px;
      background: #F5F5F5;
      border-radius: 34px;
      .transition();
      & + .tabs-item {
        margin-left: 24px;
      }
      .tabs-name {
        font-size: 36px;
        color: #979797;
        line-height: 48px;
        cursor: pointer;
      }
      &.active {
        background: @theme;
        .tabs-name {
          color: #ffffff;
        }
        &:hover {
          background: @theme;
          .transition();
          .tabs-name {
            color: #ffffff;
          }
        }
      }
      &.is-black {
        color: #979797;
        background: #232931;
        &:hover {
          color: #979797;
          background: #232931;
          .transition();
          .tabs-name {
            color: #ffffff;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .tabs-content {
      padding: 8px 12px;
      .tabs-item {
        padding: 0 16px;
        & + .tabs-item {
          margin-left: 12px;
        }
        .tabs-name {
          font-size: 20px;
          line-height: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .tabs-content {
      // padding: 8px 12px;
      .tabs-item {
        // padding: 0 16px;
        // & + .tabs-item {
        //   margin-left: 12px;
        // }
        .tabs-name {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
